import { JsonEditor } from "json-edit-react";
import React, { useMemo } from "react";

type JsonAndMessageSeparated = {
  message: string;
  json: object | null;
};

interface Props {
  log: string;
}

const getTrattedLog = (log: string): JsonAndMessageSeparated => {
  const logWithoutName = getLogWithoutName(log);
  return separateTextAndJson(logWithoutName);
};

const getLogWithoutName = (log: string): string => {
  return log.replace(/^(?:.+\s:\s)(.+)$/g, "$1");
};

const separateTextAndJson = (log: string): JsonAndMessageSeparated => {
  const jsonStart = log.indexOf("{");
  const jsonEnd = log.lastIndexOf("}") + 1;

  if (jsonStart === -1 || jsonEnd === 0) {
    return { message: log, json: null };
  }

  const textPart = log.slice(0, jsonStart).trim();
  const jsonPart = log.slice(jsonStart, jsonEnd);

  try {
    const parsedJson = JSON.parse(jsonPart);
    return { message: textPart, json: parsedJson };
  } catch (error) {
    return { message: log, json: null };
  }
};

export default function LogMessage({ log }: Props) {
  const { message, json } = useMemo(() => getTrattedLog(log), [log]);

  if (json !== null) {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <p>{message}</p>
        <JsonEditor
          data={json}
          collapseAnimationTime={200}
          indent={4}
          showCollectionCount="when-closed"
          rootName=""
          collapse={true}
          maxWidth="100%"
          restrictEdit={true}
          restrictAdd={true}
          restrictDelete={true}
          restrictDrag={true}
          enableClipboard={false}
        />
      </div>
    );
  }

  return <p>{message}</p>;
}
