import React from "react";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import { Collapse } from "../../../../components/Collapse/Collapse";
import { useAuth, useChanges, useModal } from "../../../../hooks/contexts";
import { useCustomFetch } from "../../../../hooks/async";
import menuLogo from "../../../../assets/images/menu-logo-white.png";

import {
  FolderSimpleLock,
  CirclesThree,
  FileText,
  SignOut,
  Medal,
  Gear,
  Handshake,
  ChartLineUp,
  Users,
  Money,
  Truck,
  Question,
  Factory,
  UsersThree,
  WarningOctagon,
  AppWindow,
} from "phosphor-react";

import styles from "./Sidebar.module.css";

export function Sidebar({ className, menuMobile }) {
  const Auth = useAuth();
  const Modal = useModal();
  const Changes = useChanges();
  const customFetch = useCustomFetch();

  const isAdminPage = useMatch({ path: "/admin", end: false });
  const isAdminConfigPermissoesPage = useMatch({
    path: "/admin/config-permissoes",
    end: false,
  });
  const isProductsPage = useMatch({ path: "/produtos", end: false });

  const isCommissionsPage = useMatch({ path: "/comissoes", end: false });
  const isFoliarCommissionsPage = useMatch({
    path: "/comissoes/foliar",
    end: false,
  });
  const isFetilizerCommissionsPage = useMatch({
    path: "/comissoes/adubo",
    end: false,
  });
  const isReportsCommissionsPage = useMatch({
    path: "/comissoes/relatorios",
    end: false,
  });

  const isPremiationsPage = useMatch({ path: "/premiacoes", end: false });
  const isPremiationsBaixaManualPage = useMatch({ path: "premiacoes/baixa-manual", end: false });
  const isManagerPremiationsPage = useMatch({
    path: "/premiacoes/gerente",
    end: false,
  });
  const isManagerPremiationsSituationPage = useMatch({
    path: "/premiacoes/gerente/rastreabilidade",
    end: false,
  });
  const isCommercialPremiationsPage = useMatch({
    path: "/premiacoes/comercial",
    end: false,
  });
  const isCommercialPremiationsSituationPage = useMatch({
    path: "/premiacoes/comercial/rastreabilidade",
    end: false,
  });
  const isFinancialPremiationsPage = useMatch({
    path: "/premiacoes/financeiro",
    end: false,
  });
  const isFinancialPremiationsSituationPage = useMatch({
    path: "/premiacoes/financeiro/rastreabilidade",
    end: false,
  });

  const isRdvPage = useMatch({ path: "/rdv", end: false });
  const isRdvDashboardPage = useMatch({ path: "/rdv/dashboard", end: false });
  const isRdvDashboardExpensesPage = useMatch({
    path: "/rdv/dashboard/despesas",
    end: false,
  });

  const isNonconformityPage = useMatch({
    path: "/inconformidades",
    end: false,
  });
  const isNonconformityManagerPage = useMatch({
    path: "/inconformidades/gerente",
    end: false,
  });
  const isNonconformityQualityPage = useMatch({
    path: "/inconformidades/qualidade",
    end: false,
  });

  const isFinancePage = useMatch({ path: "/financeiro", end: false });

  const isContractsPage = useLocation().pathname.includes("/contratos/");
  const isManagerContractsPage = useMatch({
    path: "/contratos/gerente",
    end: false,
  });
  const isCommercialContractsPage = useMatch({
    path: "/contratos/comercial",
    end: false,
  });
  const isFinancialContractsPage = useMatch({
    path: "/contratos/financeiro",
    end: false,
  });

  const isContractsFCPage = useMatch({
    path: "/contratos-fornecedor-cliente",
    end: false,
  });

  const isFactoryPage = useMatch({ path: "/fabrica", end: false });
  const isFactoryStockSchedulingPage = useMatch({
    path: "/fabrica/agendamento-estoque",
    end: false,
  });

  const isReportsPage = useMatch({ path: "/relatorios", end: false });
  const isReportsComissionsPage = useMatch({
    path: "/relatorios/comissoes",
    end: false,
  });

  const isConfigurationsPage = useMatch({ path: "/configuracoes", end: false });

  function havePermission(permission) {
    const permissions = Auth.permissions;
    return !!permissions.find((item) => item.permissao === permission);
  }

  React.useEffect(() => {
    const links = document.querySelectorAll(`.${styles.container} [data-link-menu]`);

    const { menuOpen, setMenuOpen } = menuMobile;

    const handleClick = () => {
      if (menuOpen) setMenuOpen(!menuOpen);
    };

    const events = ["click", "touch"];

    links.forEach((link) => {
      events.forEach((event) => link.addEventListener(event, handleClick));
    });
  }, [menuMobile]);

  async function handleSignOut() {
    if (Changes.isChangesDetected) {
      const allowNavigate = await Modal.confirm(
        "Você realizou mudanças que ainda não foram salvas!<br>Deseja continuar mesmo assim?"
      );
      if (allowNavigate) {
        Changes.reset();
        Auth.signOut();
      }
    } else {
      Auth.signOut();
    }
  }

  async function redirectToDataAnalytics() {
    const json = await customFetch("/redirect/redirectToDataAnalytics", {
      method: "GET",
    });
    window.location.href = `https://dataanalytics.agrocp.agr.br/login/?token=${"agrocp-" + json.token}`;
  }

  async function redirectToRPA() {
    const json = await customFetch("/redirect/redirectToRPA", {
      method: "GET",
    });
    window.location.href = `https://rpa.agrocp.agr.br/login/AcessoPortal/?token=${"agrocp-" + json.token}`;
  }

  async function redirectToApollo() {
    const json = await customFetch("/redirect/redirectToApollo", {
      method: "GET",
    });
    window.location.href = `https://apollo.agrocp.agr.br/login/?token=${"agrocp-" + json.token}`;
  }

  return (
    <aside className={`${styles.container} ${className}`}>
      <header className={styles.header}>
        <img src={menuLogo} alt="logo cp" width="180" />
      </header>
      <ul className={`customScrollbar ${styles.menu}`}>
        <li>
          <NavLink to="aplicativos" className={styles.menuLink} data-link-menu>
            <AppWindow size={20} color="#f2f2f2" weight="fill" /> Aplicativos
          </NavLink>
        </li>
        {havePermission("MENU_ADMIN") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isAdminPage ? "active" : ""}`}
              label={
                <>
                  <FolderSimpleLock size={20} color="#f2f2f2" weight="fill" /> Admin
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                <li>
                  <Collapse
                    className={`${styles.menuLink} ${isAdminConfigPermissoesPage ? "active" : ""}`}
                    label={"Configurações Permissão"}
                  >
                    <ul className={styles.menuLinkDropdown}>
                      <li>
                        <NavLink to="admin/configuracoes-permissao/sistemas" className={styles.menuLink} data-link-menu>
                          Sistemas
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="admin/configuracoes-permissao/tipos" className={styles.menuLink} data-link-menu>
                          Tipos
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="admin/configuracoes-permissao/permissoes"
                          className={styles.menuLink}
                          data-link-menu
                        >
                          Permissões
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li>
                  <NavLink to="admin/usuarios" className={styles.menuLink} data-link-menu>
                    Usuários
                  </NavLink>
                </li>
                <li>
                  <NavLink to="admin/grupos-de-permissoes" className={styles.menuLink} data-link-menu>
                    Grupos de Permissões
                  </NavLink>
                </li>
                <li>
                  <NavLink to="admin/logs" className={styles.menuLink} data-link-menu>
                    Logs
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li>
        )}
        {havePermission("MENU_PRODUTOS") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isProductsPage ? "active" : ""}`}
              label={
                <>
                  <CirclesThree size={20} color="#f2f2f2" weight="fill" /> Produtos
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                {havePermission("MENU_GRUPO") && (
                  <li>
                    <NavLink to="produtos/grupos" className={styles.menuLink} data-link-menu>
                      Grupos
                    </NavLink>
                  </li>
                )}
                {havePermission("MENU_FORMULA") && (
                  <li>
                    <NavLink to="produtos/formulas" className={styles.menuLink} data-link-menu>
                      Fórmulas
                    </NavLink>
                  </li>
                )}
                {havePermission("MENU_MICRO") && (
                  <li>
                    <NavLink to="produtos/micros" className={styles.menuLink} data-link-menu>
                      Micros
                    </NavLink>
                  </li>
                )}
                {havePermission("MENU_EMBALAGEM") && (
                  <li>
                    <NavLink to="produtos/embalagens" className={styles.menuLink} data-link-menu>
                      Embalagens
                    </NavLink>
                  </li>
                )}
                {havePermission("MENU_TABELA_PRECO") && (
                  <li>
                    <NavLink to="produtos/tabela-de-precos" className={styles.menuLink} data-link-menu>
                      Tabela de Preços
                    </NavLink>
                  </li>
                )}
                {havePermission("MENU_TABELA_VIGENCIA") && (
                  <li>
                    <NavLink to="produtos/tabelas-de-vigencia" className={styles.menuLink} data-link-menu>
                      Tabelas de Vigência
                    </NavLink>
                  </li>
                )}
                {havePermission("MENU_REPLICAR_TABELA") && (
                  <li>
                    <NavLink to="produtos/replicar-vigencia" className={styles.menuLink} data-link-menu>
                      Replicar Vigência
                    </NavLink>
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        {havePermission("MENU_COMISSAO") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isCommissionsPage ? "active" : ""}`}
              label={
                <>
                  <Handshake size={20} color="#f2f2f2" weight="fill" /> Comissões
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                <li>
                  <Collapse
                    className={`${styles.menuLink} ${isFoliarCommissionsPage ? "active" : ""}`}
                    label={"Foliar"}
                  >
                    <ul className={styles.menuLinkDropdown}>
                      {havePermission("MENU_FAIXA_PRECO_FOLIAR") && (
                        <li>
                          <NavLink to="comissoes/foliar/faixas-de-preco" className={styles.menuLink} data-link-menu>
                            Faixas de Preço
                          </NavLink>
                        </li>
                      )}
                      {havePermission("MENU_PERCENTUAL_COMISSAO_FOLIAR") && (
                        <li>
                          <NavLink to="comissoes/foliar/percentual-comissao" className={styles.menuLink} data-link-menu>
                            Percentual Comissão
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
                <li>
                  <Collapse
                    className={`${styles.menuLink} ${isFetilizerCommissionsPage ? "active" : ""}`}
                    label={"Adubo"}
                  >
                    <ul className={styles.menuLinkDropdown}>
                      {havePermission("VER_MENU_COMISSAO_TABELA") && (
                        <li>
                          <NavLink
                            to={"comissoes/adubo/tabelas-de-comissoes"}
                            className={styles.menuLink}
                            data-link-menu
                          >
                            Tabelas de Comissões
                          </NavLink>
                        </li>
                      )}
                      <li>
                        <NavLink
                          to={"comissoes/adubo/comissoes-de-contratos"}
                          className={styles.menuLink}
                          data-link-menu
                        >
                          Comissões de Contratos
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to={"comissoes/adubo/faixa-vigencia"} className={styles.menuLink} data-link-menu>
                          Faixa Vigência
                        </NavLink>
                      </li> */}
                    </ul>
                  </Collapse>
                </li>
                <li>
                  <Collapse
                    className={`${styles.menuLink} ${isReportsCommissionsPage ? "active" : ""}`}
                    label={"Relatórios"}
                  >
                    <ul className={styles.menuLinkDropdown}>
                      <li>
                        <NavLink to={"comissoes/relatorios/por-formula"} className={styles.menuLink} data-link-menu>
                          Por Fórmula
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"comissoes/relatorios/por-vendedor"} className={styles.menuLink} data-link-menu>
                          Por Vendedor
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              </ul>
            </Collapse>
          </li>
        )}
        {havePermission("MENU_NOVA_PREMIACAO") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isPremiationsPage ? "active" : ""}`}
              label={
                <>
                  <Medal size={20} color="#f2f2f2" weight="fill" /> Premiações
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                {havePermission("MENU_PREMIACOES_BAIXA_MANUAL") && (
                  <li>
                    <Collapse
                      className={`${styles.menuLink} ${isPremiationsBaixaManualPage ? "active" : ""}`}
                      label={"Baixa Manual"}
                    >
                      <ul className={styles.menuLinkDropdown}>
                        <li>
                          <NavLink
                            to="premiacoes/baixa-manual/pedidos-baixa"
                            className={styles.menuLink}
                            data-link-menu
                          >
                            Pedidos
                          </NavLink>
                        </li>

                        <li>
                          <NavLink to="premiacoes/baixa-manual/historico" className={styles.menuLink} data-link-menu>
                            Histórico
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )}
                {havePermission("MENU_NOVA_PREMIACAO_GERENTE") && (
                  <li>
                    <Collapse
                      className={`${styles.menuLink} ${isManagerPremiationsPage ? "active" : ""}`}
                      label={"Gerente"}
                    >
                      <ul className={styles.menuLinkDropdown}>
                        <li>
                          <NavLink to="premiacoes/gerente/solicitar" className={styles.menuLink} data-link-menu>
                            Solicitar
                          </NavLink>
                        </li>
                        <li>
                          <Collapse
                            className={`${styles.menuLink} ${isManagerPremiationsSituationPage ? "active" : ""}`}
                            label={"Rastreabilidade"}
                          >
                            <ul className={styles.menuLinkDropdown}>
                              <li>
                                <NavLink
                                  to="premiacoes/gerente/rastreabilidade/geral"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Geral
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/gerente/rastreabilidade/em-analise-comercial"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Em Análise Comercial
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/gerente/rastreabilidade/em-analise-financeiro"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Em Análise Financeiro
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/gerente/rastreabilidade/pagos"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Pagos
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/gerente/rastreabilidade/reprovados"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Reprovados
                                </NavLink>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                        <li>
                          <NavLink to="premiacoes/gerente/comprovante" className={styles.menuLink} data-link-menu>
                            Comprovante
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )}
                {havePermission("MENU_NOVA_PREMIACAO_COMERCIAL") && (
                  <li>
                    <Collapse
                      className={`${styles.menuLink} ${isCommercialPremiationsPage ? "active" : ""}`}
                      label={"Comercial"}
                    >
                      <ul className={styles.menuLinkDropdown}>
                        <li>
                          <NavLink
                            to="premiacoes/comercial/em-analise-comercial"
                            className={styles.menuLink}
                            data-link-menu
                          >
                            Em Análise Comercial
                          </NavLink>
                        </li>
                        <li>
                          <Collapse
                            className={`${styles.menuLink} ${isCommercialPremiationsSituationPage ? "active" : ""}`}
                            label={"Rastreabilidade"}
                          >
                            <ul className={styles.menuLinkDropdown}>
                              <li>
                                <NavLink
                                  to="premiacoes/comercial/rastreabilidade/geral"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Geral
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/comercial/rastreabilidade/em-analise-financeiro"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Em Análise Financeiro
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/comercial/rastreabilidade/pagos"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Pagos
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/comercial/rastreabilidade/reprovados"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Reprovados
                                </NavLink>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                        <li>
                          <NavLink to="premiacoes/comercial/comprovante" className={styles.menuLink} data-link-menu>
                            Comprovante
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )}
                {havePermission("MENU_NOVA_PREMIACAO_FINANCEIRO") && (
                  <li>
                    <Collapse
                      className={`${styles.menuLink} ${isFinancialPremiationsPage ? "active" : ""}`}
                      label={"Financeiro"}
                    >
                      <ul className={styles.menuLinkDropdown}>
                        <li>
                          <NavLink
                            to="premiacoes/financeiro/em-analise-financeiro"
                            className={styles.menuLink}
                            data-link-menu
                          >
                            Em Análise Financeiro
                          </NavLink>
                        </li>
                        <li>
                          <Collapse
                            className={`${styles.menuLink} ${isFinancialPremiationsSituationPage ? "active" : ""}`}
                            label={"Rastreabilidade"}
                          >
                            <ul className={styles.menuLinkDropdown}>
                              <li>
                                <NavLink
                                  to="premiacoes/financeiro/rastreabilidade/geral"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Geral
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/financeiro/rastreabilidade/em-analise-comercial"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Em Análise Comercial
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/financeiro/rastreabilidade/pagos"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Pagos
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="premiacoes/financeiro/rastreabilidade/reprovados"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Reprovados
                                </NavLink>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                        <li>
                          <NavLink to="premiacoes/financeiro/comprovante" className={styles.menuLink} data-link-menu>
                            Comprovante
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        {havePermission("MENU_RDV") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isRdvPage ? "active" : ""}`}
              label={
                <>
                  <Users size={20} color="#f2f2f2" weight="fill" /> RDV
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                {havePermission("VER_MENU_RDV_DASHBOARD") && (
                  <li>
                    <Collapse
                      className={`${styles.menuLink} ${isRdvDashboardPage ? "active" : ""}`}
                      label={"Dashboard"}
                    >
                      <ul className={styles.menuLinkDropdown}>
                        <li>
                          <Collapse
                            className={`${styles.menuLink} ${isRdvDashboardExpensesPage ? "active" : ""}`}
                            label={"Despesas"}
                          >
                            <ul className={styles.menuLinkDropdown}>
                              <li>
                                <NavLink
                                  to="rdv/dashboard/despesas/pendentes"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Pendentes
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="rdv/dashboard/despesas/liberadas"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Liberadas
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="rdv/dashboard/despesas/recusadas"
                                  className={styles.menuLink}
                                  data-link-menu
                                >
                                  Recusadas
                                </NavLink>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )}
                {havePermission("VER_MENU_RDV_CATEGORIA") && (
                  <li>
                    <NavLink to="rdv/categorias" className={styles.menuLink} data-link-menu>
                      Categorias
                    </NavLink>
                  </li>
                )}
                {havePermission("VER_MENU_RDV_FORMA_PAGAMENTO") && (
                  <li>
                    <NavLink to="rdv/formas-de-pagamento" className={styles.menuLink} data-link-menu>
                      Formas de Pagamento
                    </NavLink>
                  </li>
                )}
                {havePermission("VER_MENU_RDV_FORNECEDORES") && (
                  <li>
                    <NavLink to="rdv/fornecedores" className={styles.menuLink} data-link-menu>
                      Fornecedores
                    </NavLink>
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        {havePermission("VER_MENU_INCONFORMIDADE") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isNonconformityPage ? "active" : ""}`}
              label={
                <>
                  <WarningOctagon size={20} color="#f2f2f2" weight="fill" /> Inconformidades
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                {havePermission("VER_MENU_INCONFORMIDADE_GERENTE") && (
                  <li>
                    <Collapse
                      className={`${styles.menuLink} ${isNonconformityManagerPage ? "active" : ""}`}
                      label={<>Gerente</>}
                    >
                      <ul className={styles.menuLinkDropdown}>
                        <li>
                          <NavLink to="inconformidades/gerente/pendentes" className={styles.menuLink} data-link-menu>
                            Pendentes
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="inconformidades/gerente/aprovadas" className={styles.menuLink} data-link-menu>
                            Aprovadas
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="inconformidades/gerente/recusadas" className={styles.menuLink} data-link-menu>
                            Recusadas
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )}
                {havePermission("VER_MENU_INCONFORMIDADE_QUALIDADE") && (
                  <Collapse
                    className={`${styles.menuLink} ${isNonconformityQualityPage ? "active" : ""}`}
                    label={<>Qualidade</>}
                  >
                    <ul className={styles.menuLinkDropdown}>
                      <li>
                        <NavLink to="inconformidades/qualidade/pendentes" className={styles.menuLink} data-link-menu>
                          Pendentes
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="inconformidades/qualidade/aprovadas" className={styles.menuLink} data-link-menu>
                          Aprovadas
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="inconformidades/qualidade/recusadas" className={styles.menuLink} data-link-menu>
                          Recusadas
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        {havePermission("MENU_FINANCEIRO") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isFinancePage ? "active" : ""}`}
              label={
                <>
                  <Money size={20} color="#f2f2f2" weight="fill" /> Financeiro
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                {havePermission("MENU_FINANCEIRO_BANCO") && (
                  <li>
                    <NavLink to="financeiro/bancos" className={styles.menuLink} data-link-menu>
                      Bancos
                    </NavLink>
                  </li>
                )}
                {havePermission("MENU_FINANCEIRO_FORMA_PAGAMENTO") && (
                  <li>
                    <NavLink to="financeiro/formas-de-pagamento" className={styles.menuLink} data-link-menu>
                      Formas de Pagamento
                    </NavLink>
                  </li>
                )}
                {havePermission("MENU_FINANCEIRO_DOCUMENTO") && (
                  <li>
                    <NavLink to="financeiro/documentos" className={styles.menuLink} data-link-menu>
                      Documentos
                    </NavLink>
                  </li>
                )}
                {havePermission("MENU_FINANCEIRO_CONTA") && (
                  <li>
                    <NavLink to="financeiro/contas" className={styles.menuLink} data-link-menu>
                      Contas
                    </NavLink>
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        {havePermission("VER_MENU_CONTRATO") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isContractsPage ? "active" : ""}`}
              label={
                <>
                  <Handshake size={20} color="#f2f2f2" weight="fill" /> Contratos
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                <li>
                  <Collapse
                    className={`${styles.menuLink} ${isManagerContractsPage ? "active" : ""}`}
                    label={"Gerente"}
                  >
                    <ul className={styles.menuLinkDropdown}>
                      {havePermission("VER_MENU_SOLICITA_CONTRATO") && (
                        <li>
                          <NavLink
                            to="contratos/gerente/solicitacao-de-contrato"
                            className={styles.menuLink}
                            data-link-menu
                          >
                            Solicitação de Contrato
                          </NavLink>
                        </li>
                      )}
                      {havePermission("VER_MENU_SOLICITA_CONTRATO_CLIENTE") && (
                        <li>
                          <NavLink
                            to="contratos/gerente/solicitacoes-aguardando-cliente"
                            className={styles.menuLink}
                            data-link-menu
                          >
                            Solicitações Aguardando Cliente
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
                <li>
                  <Collapse
                    className={`${styles.menuLink} ${isCommercialContractsPage ? "active" : ""}`}
                    label={"Comercial"}
                  >
                    <ul className={styles.menuLinkDropdown}>
                      {havePermission("VER_MENU_SOLICITA_CONTRATO_COMERCIAL") && (
                        <li>
                          <NavLink
                            to="contratos/comercial/solicitacoes-pendentes"
                            className={styles.menuLink}
                            data-link-menu
                          >
                            Solicitações Pendentes
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
                <li>
                  <Collapse
                    className={`${styles.menuLink} ${isFinancialContractsPage ? "active" : ""}`}
                    label={"Financeiro"}
                  >
                    <ul className={styles.menuLinkDropdown}>
                      {havePermission("VER_MENU_SOLICITA_CONTRATO_FINANCEIRO") && (
                        <li>
                          <NavLink to="contratos/financeiro/solicitacoes" className={styles.menuLink} data-link-menu>
                            Solicitações
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
                {havePermission("VER_MENU_SOLICITA_CONTRATO_HISTORICO") && (
                  <li>
                    <NavLink to="contratos/historico-solicitacoes" className={styles.menuLink} data-link-menu>
                      Histórico Solicitações
                    </NavLink>
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        <li>
          <Collapse
            className={`${styles.menuLink} ${isContractsFCPage ? "active" : ""}`}
            label={
              <>
                <UsersThree size={20} color="#f2f2f2" weight="fill" /> Contratos F.C.
              </>
            }
          >
            <ul className={styles.menuLinkDropdown}>
              <li>
                <NavLink to="contratos-fornecedor-cliente/contratado" className={styles.menuLink} data-link-menu>
                  Contratado
                </NavLink>
              </li>
              <li>
                <NavLink to="contratos-fornecedor-cliente/contrato" className={styles.menuLink} data-link-menu>
                  Contrato
                </NavLink>
              </li>
              <li>
                <NavLink to="contratos-fornecedor-cliente/exigencias" className={styles.menuLink} data-link-menu>
                  Exigências
                </NavLink>
              </li>
              <li>
                <NavLink to="contratos-fornecedor-cliente/objetos" className={styles.menuLink} data-link-menu>
                  Objetos
                </NavLink>
              </li>
              <li>
                <NavLink to="contratos-fornecedor-cliente/penalidades" className={styles.menuLink} data-link-menu>
                  Penalidades
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="contratos-fornecedor-cliente/periodicidades-pagamento"
                  className={styles.menuLink}
                  data-link-menu
                >
                  Periodicidades Pagamento
                </NavLink>
              </li>
            </ul>
          </Collapse>
        </li>
        <li>
          <Collapse
            className={`${styles.menuLink} ${isFactoryPage ? "active" : ""}`}
            label={
              <>
                <Factory size={20} color="#f2f2f2" weight="fill" /> Fábrica
              </>
            }
          >
            <ul className={styles.menuLinkDropdown}>
              <li>
                <Collapse
                  className={`${styles.menuLink} ${isFactoryStockSchedulingPage ? "active" : ""}`}
                  label={"Agendamento Estoque"}
                >
                  <ul className={styles.menuLinkDropdown}>
                    <li>
                      <NavLink
                        to="fabrica/agendamento-estoque/inserir-abater"
                        className={styles.menuLink}
                        data-link-menu
                      >
                        Inserir/Abater
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="fabrica/agendamento-estoque/consultar" className={styles.menuLink} data-link-menu>
                        Consultar
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
          </Collapse>
        </li>
        {havePermission("MENU_RELATORIOS") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isReportsPage ? "active" : ""}`}
              label={
                <>
                  <FileText size={20} color="#f2f2f2" weight="fill" /> Relatórios
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                {havePermission("MENU_COMISSOES_RELATORIO") && (
                  <li>
                    <Collapse
                      className={`${styles.menuLink} ${isReportsComissionsPage ? "active" : ""}`}
                      label={"Comissões"}
                    >
                      <ul className={styles.menuLinkDropdown}>
                        {havePermission("MENU_COMISSOES_RELATORIO_ANALITICO") && (
                          <li>
                            <NavLink to="relatorios/comissoes/analitico" className={styles.menuLink} data-link-menu>
                              Analítico
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        {havePermission("MENU_CONFIGURACAO") && (
          <li>
            <Collapse
              className={`${styles.menuLink} ${isConfigurationsPage ? "active" : ""}`}
              label={
                <>
                  <Gear size={20} color="#f2f2f2" weight="fill" /> Configurações
                </>
              }
            >
              <ul className={styles.menuLinkDropdown}>
                <li>
                  <NavLink to="configuracoes/geral" className={styles.menuLink} data-link-menu>
                    Geral
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="configuracoes/configs-tablet"
                    className={styles.menuLink}
                    data-link-menu
                  >
                    Configs Tablet
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="configuracoes/permissoes-pedido" className={styles.menuLink} data-link-menu>
                    Permissões Pedido
                  </NavLink>
                </li>
                <li>
                  <NavLink to="configuracoes/categorias-pessoas" className={styles.menuLink} data-link-menu>
                    Categorias Pessoas
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="configuracoes/vincular-gerente-vendedor" className={styles.menuLink} data-link-menu>
                    Vincular Gerente/Vendedor
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li>
        )}
        <li>
          <button className={styles.menuLink} onClick={redirectToApollo}>
            <Question size={20} color="#f2f2f2" weight="fill" /> Apollo
          </button>
        </li>
        {havePermission("MENU_DATA_ANALYTICS") && (
          <li>
            <button className={styles.menuLink} onClick={redirectToDataAnalytics}>
              <ChartLineUp size={20} color="#f2f2f2" weight="fill" /> CP Analytics
            </button>
          </li>
        )}
        {havePermission("MENU_RPA") && (
          <li>
            <button className={styles.menuLink} onClick={redirectToRPA}>
              <Truck size={20} color="#f2f2f2" weight="fill" /> RPA
            </button>
          </li>
        )}
        <li>
          <button className={styles.menuLink} onClick={handleSignOut}>
            <SignOut size={20} color="#f2f2f2" weight="fill" /> Sair
          </button>
        </li>
      </ul>
    </aside>
  );
}
