import React from "react";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { Route, Routes } from "react-router-dom";
import { ValidityTableList } from "./ValidityTableList/ValidityTableList";
import { useModal } from "../../../hooks/contexts";
import { useForm } from "../../../hooks/form";
import { NewValidityTable } from "./NewValidityTable/NewValidityTable";
import { ValidityTableItems } from "./ValidityTableItems/ValidityTableItems";
import { UpdateValidityTable } from "./UpdateValidityTable/UpdateValidityTable";

export function ProductValidityTables() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const [selectedTable, setSelectedTable] = React.useState(null);

  const searchedValidity = useForm({ type: "", required: false });
  const [validityList, setValidityList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [noMoreValidity, setNoMoreValidity] = React.useState(false);
  const searchingValidityList = useLoading();

  const searchValidityList = React.useCallback(
    async (description = "", page = 0) => {
      try {
        searchingValidityList.setLoading(true);
        const json = await customFetch("/products/searchValidityList", {
          body: {
            descricao: description,
            tamanho: 100,
            pagina: page,
          },
        });
        if (json.status === 200) {
          if (page === 0) {
            setValidityList(() => {
              return [...json.object];
            });
          } else {
            setValidityList((old) => {
              return [...old, ...json.object];
            });
          }
          setCurrentPage(page + 1);
          setNoMoreValidity(false);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setNoMoreValidity(true);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingValidityList.setLoading(false);
      }
    },
    [Modal, customFetch, searchingValidityList]
  );

  return (
    <section>
      <Routes>
        <Route
          path="/"
          element={
            <ValidityTableList
              setSelectedTable={setSelectedTable}
              searchedValidity={searchedValidity}
              currentPage={currentPage}
              noMoreValidity={noMoreValidity}
              searchValidityList={searchValidityList}
              searchingValidityList={searchingValidityList}
              validityList={{ value: validityList, setValue: setValidityList }}
            />
          }
        />
        <Route path="nova" element={<NewValidityTable setValidityList={setValidityList} />} />
        <Route
          path="atualizar"
          element={
            <UpdateValidityTable
              selectedTable={{ value: selectedTable, setValue: setSelectedTable }}
              setValidityList={setValidityList}
            />
          }
        />
        <Route
          path="itens/*"
          element={<ValidityTableItems selectedTable={{ value: selectedTable, setValue: setSelectedTable }} />}
        />
      </Routes>
    </section>
  );
}
