import React from "react";
import { LinkButton } from "../../../../components/Form";
import { usePreviousRoute } from "../../../../hooks/navigation";
import { UseBaixasManuaisRealizadas } from "../hooks/useBaixasManuaisRealizadas";
import { Navigate } from "react-router-dom";

import styles from "./Detalhes.module.css";
import { formatDate, formatMoney } from "../../../../helpers/format";

interface Props {
  data: UseBaixasManuaisRealizadas;
}

export default function Detalhes({ data }: Props) {
  const previousRoute = usePreviousRoute();

  if (data.baixaManualSelecionada === null) return <Navigate to={previousRoute} />;

  return (
    <div className="container">
      <nav className={styles.navigationContainer}>
        <LinkButton buttonStyle="backButton" to={previousRoute} />
        <h2>BAIXA DE PREMIAÇÕES DO PEDIDO: {data.baixaManualSelecionada.idPedido}</h2>
        <span />
      </nav>
      <span className="separator" />
      <div>
        <div className={styles.informacoesGeraisContainer}>
          <h3 className={styles.sectiontitle}>INFORMAÇÕES GERAIS DA BAIXA</h3>
          <ul className={styles.sectionUl}>
            <li>
              <p>
                <span className={styles.sectionDetalheTitle}>Data Baixa:</span>{" "}
                {formatDate(data.baixaManualSelecionada.dataBaixa, "dd/MM/yyyy às hh:mm")}
              </p>
            </li>
            <li>
              <p>
                <span className={styles.sectionDetalheTitle}>Responsável:</span>{" "}
                {data.baixaManualSelecionada.nomeResponsavel}
              </p>
            </li>
            <li>
              <p>
                <span className={styles.sectionDetalheTitle}>Cliente Pedido:</span>{" "}
                {data.baixaManualSelecionada.nomeCliente}
              </p>
            </li>
            <li>
              <p>
                <span className={styles.sectionDetalheTitle}>Total Baixado:</span>{" "}
                {formatMoney(data.baixaManualSelecionada.totalBaixado)}
              </p>
            </li>
          </ul>
        </div>
        <span className="separator" />
        <div>
          <h3 className={styles.sectiontitle}>PREMIAÇÕES BAIXADAS</h3>
          <ul className={styles.sectionUlPremiacoes}>
            {data.baixaManualSelecionada?.pagamentosBaixa.map((pagamentoBaixa) => (
              <li key={pagamentoBaixa.pagamento.id}>
                <h3 className={styles.subSectionTitle}>
                  PREMIAÇÃO {pagamentoBaixa.premiacao.id} | {pagamentoBaixa.premiacao.pessoa.toUpperCase()}
                </h3>
                <ul className={styles.subSectionUl}>
                  <li>
                    <p>
                      <span className={styles.sectionDetalheTitle}>Valor Pago:</span>{" "}
                      {formatMoney(pagamentoBaixa.pagamento.valor)}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={styles.sectionDetalheTitle}>Forma Pagamento:</span>{" "}
                      {pagamentoBaixa.pagamento.formaPagamento}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={styles.sectionDetalheTitle}>Cancelado:</span>{" "}
                      {pagamentoBaixa.pagamento.cancelado === "N" ? "Não" : "Sim"}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={styles.sectionDetalheTitle}>Saldo Ainda Disponível:</span>{" "}
                      {formatMoney(pagamentoBaixa.premiacao.saldo)}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={styles.sectionDetalheTitle}>Observação:</span>{" "}
                      {pagamentoBaixa.pagamento.observacao ?? "---"}
                    </p>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
