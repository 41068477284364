import React, { useEffect } from "react";
import {
  Button,
  Input,
  LinkButton,
  Select,
  Textarea,
} from "../../../../../components/Form";
import { usePreviousRoute } from "../../../../../hooks/navigation";
import { Navigate } from "react-router-dom";
import { Circle } from "../../../../../components/Loading";

import styles from "./InserirAtualizar.module.css";
import { UsePermissoes } from "../hooks/usePermissoes";

interface Props {
  data: UsePermissoes;
  telaAtualizar?: boolean;
}

export default function InserirAtualizar({ data, telaAtualizar }: Props) {
  const previousRoute = usePreviousRoute();

  useEffect(() => {
    if (telaAtualizar && data.permissaoSelecionada.value != null) {
      data.sistema.setValue({
        value: data.permissaoSelecionada.value.sistema,
        label: data.permissaoSelecionada.value.sistema.nome,
      });
      data.tipo.setValue({
        value: {
          ...data.permissaoSelecionada.value.tipo,
          sistemaId: data.permissaoSelecionada.value.sistema.id,
          sistemaNome: data.permissaoSelecionada.value.sistema.nome,
          status: "A",
        },
        label: data.permissaoSelecionada.value.tipo.nome,
      });
      data.nomePermissao.setValue(
        data.permissaoSelecionada.value.permissao.nome
      );
      data.descricaoPermissao.setValue(
        data.permissaoSelecionada.value.permissao.descricao
      );
      data.status.setValue(
        data.statusOptions.find(
          (status) =>
            status.value === data.permissaoSelecionada.value?.permissao.status
        )!
      );

      data.buscarTipos.buscar(data.permissaoSelecionada.value.sistema.id);
    }
  }, []); // eslint-disable-line

  if (telaAtualizar && data.permissaoSelecionada.value == null)
    return <Navigate to={previousRoute} />;

  return (
    <div className="container">
      {!data.salvarAlteracoesPermissao.loading ? (
        <>
          <nav>
            <LinkButton buttonStyle="backButton" to={previousRoute} />
          </nav>
          <span className="separator" />
          <div className={styles.formContainer}>
            <div className={styles.formContainer__nomePermissao}>
              <label htmlFor="nomePermissao" className="label">
                Nome Permissão
              </label>
              <Input
                id="nomePermissao"
                placeholder="Digite o nome da permissão"
                style={{ textTransform: "uppercase" }}
                value={data.nomePermissao.value}
                onChange={data.nomePermissao.onChange}
                onBlur={data.nomePermissao.onBlur}
                error={data.nomePermissao.error}
              />
            </div>
            <div className={styles.formContainer__descricaoPermissao}>
              <label htmlFor="descricaoPermissao" className="label">
                Descrição Permissão
              </label>
              <Textarea
                id="descricaoPermissao"
                placeholder="Digite a descrição da permissão"
                value={data.descricaoPermissao.value}
                onChange={data.descricaoPermissao.onChange}
                onBlur={data.descricaoPermissao.onBlur}
                error={data.descricaoPermissao.error}
                className={styles.formContainer__descricaoPermissao__field}
                rows="4"
              />
            </div>
            <div>
              <label htmlFor="sistema" className="label">
                Sistema
              </label>
              <Select
                id="sistema"
                placeholder="Selecione o sistema"
                value={data.sistema.value}
                onChange={(value) => {
                  data.sistema.onChange(value);
                  if (!value) {
                    data.tipo.reset();
                    data.setTipoOptions([]);
                  } else {
                    data.buscarTipos.buscar(value.value.id);
                  }
                }}
                onBlur={data.sistema.onBlur}
                error={data.sistema.error}
                options={data.sistemaOptions}
                isLoading={data.buscarSistemas.loading}
                isSearchable={false}
              />
            </div>
            <div>
              <label htmlFor="tipo" className="label">
                Tipo
              </label>
              <Select
                id="tipo"
                placeholder="Selecione o tipo"
                value={data.tipo.value}
                onChange={data.tipo.onChange}
                onBlur={data.tipo.onBlur}
                error={data.tipo.error}
                options={data.tipoOptions}
                isLoading={data.buscarTipos.loading}
                isSearchable={false}
                isDisabled={
                  data.buscarSistemas.loading ||
                  !data.sistema.value ||
                  data.buscarTipos.loading
                }
              />
            </div>
            {telaAtualizar && (
              <div className={styles.formContainer__status}>
                <label htmlFor="status" className="label">
                  Status
                </label>
                <Select
                  id="status"
                  placeholder="Selecione o status"
                  value={data.status.value}
                  onChange={data.status.onChange}
                  onBlur={data.status.onBlur}
                  error={data.status.error}
                  options={data.statusOptions}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (telaAtualizar)
                  data.salvarAlteracoesPermissao.salvar(
                    data.permissaoSelecionada.value?.permissao.id
                  );
                else data.salvarAlteracoesPermissao.salvar();
              }}
            >
              Salvar
            </Button>
          </div>
        </>
      ) : (
        <div
          className="loadingContainer"
          style={{ height: "500px", justifyContent: "center" }}
        >
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}
