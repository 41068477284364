import { QueryClient, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxios from "../../../api/hooks/useAxiosInstance";

type RequestBody = {
  idTabela: number;
  idMicro: number;
  precoMicro: number;
  precoCustoMicro: number;
};

type FetchResponse = DefaultFetchResponse<any>;

export function useUpdateValidityTableMicro(
  options?: UseMutationOptions<
    AxiosResponse<FetchResponse, any>,
    AxiosError<DefaultErrorResponse, any>,
    RequestBody,
    unknown
  >,
  queryClient?: QueryClient
) {
  const axios = useAxios();

  const fetchData = async (body: RequestBody): Promise<AxiosResponse<FetchResponse>> => {
    const response = await axios.post<FetchResponse>("/products/updateValidityTableMicro", body);
    return response;
  };

  const query = useMutation(
    {
      mutationFn: fetchData,
      networkMode: "always",
      ...options,
    },
    queryClient
  );

  return {
    ...query,
    data: query.data?.data.object,
  };
}
