import React from "react";

import { useCustomFetch, useDebounce } from "../../../hooks/async";
import { useModal } from "../../../hooks/contexts";
import { usePagination } from "../../../hooks/pagination";

import { Select } from "../../../components/Form/Select/Select";
import { Input } from "../../../components/Form";
import { Button } from "../../../components/Form";
import { Table, THead, TBody, Tr, Th, Td } from "../../../components/Data/Table";
import { Paginate } from "../../../components/Paginate/Paginate";

import { Circle } from "../../../components/Loading/Circle/Circle";

import styles from "./Logs.module.css";
import { formatDate } from "../../../helpers/format";
import { Controller, useForm } from "react-hook-form";
import LogMessage from "./components/LogMessage";

export function Logs() {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const pagination = usePagination();
  const [isSearchingOptionsUser, setIsSearchingOptionsUser] = React.useState(false);
  const [optionsUser, setOptionsUser] = React.useState([]);
  const [isSearchingOptionsArea, setIsSearchingOptionsArea] = React.useState(false);
  const [optionsArea, setOptionsArea] = React.useState([]);
  const [isSearchingLogs, setIsSearchingLogs] = React.useState(false);
  const [logs, setLogs] = React.useState([]);

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm();

  const user = watch("user");

  const clearLogs = React.useCallback(() => {
    setLogs([]);
    pagination.reset();
  }, [pagination]);

  const searchUsers = useDebounce(async (value) => {
    try {
      setIsSearchingOptionsUser(true);
      const json = await customFetch("/admin/searchUsers", {
        body: {
          pesquisa: value || "%%%%",
        },
      });
      if (json.status === 200) {
        setOptionsUser(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      setIsSearchingOptionsUser(false);
    }
  });

  const SearchLogAreas = React.useCallback(async () => {
    try {
      setIsSearchingOptionsArea(true);
      const json = await customFetch("/admin/searchLogAreas", {
        body: {
          pesquisa: "",
        },
      });
      if (json.status === 200) {
        setOptionsArea(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      setIsSearchingOptionsArea(false);
    }
  }, [Modal, customFetch]);

  const searchTotalLogRecords = React.useCallback(
    ({ user, initialDate, finalDate, area, logDescription }) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/admin/searchTotalLogRecords", {
            body: {
              idPessoa: Number(user.value.idUsuario),
              dataInicial: new Date(initialDate).toISOString(),
              dataFinal: new Date(finalDate).toISOString(),
              area: area ? Number(area.value.idLogArea) : 0,
              descricao: logDescription || "",
              tamanho: pagination.maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchLogsPromise = React.useCallback(
    (page, { user, initialDate, finalDate, area, logDescription }) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/admin/searchLogs", {
            body: {
              idPessoa: Number(user.value.idUsuario),
              dataInicial: new Date(initialDate).toISOString(),
              dataFinal: new Date(finalDate).toISOString(),
              area: area ? Number(area.value.idLogArea) : 0,
              descricao: logDescription || "",
              pagina: page ? Number(page) - 1 : 0,
              tamanho: pagination.maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const handleChangeForm = React.useCallback(
    async (target, formOnChange, message) => {
      if (logs.length) {
        const confirm = await Modal.confirm(message);
        if (confirm) {
          clearLogs();
          formOnChange(target);
        }
      } else {
        formOnChange(target);
      }
    },
    [Modal, clearLogs, logs.length]
  );

  const onPageChange = React.useCallback(
    async (page, form) => {
      try {
        setIsSearchingLogs(true);
        const jsonLogs = await searchLogsPromise(page, form);
        if (jsonLogs.status === 200) {
          setLogs(jsonLogs.object);
        } else if (jsonLogs === 500) {
          Modal.error(jsonLogs.message, jsonLogs.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        setIsSearchingLogs(false);
      }
    },
    [Modal, searchLogsPromise]
  );

  const searchLogs = React.useCallback(
    async (e, form) => {
      e.preventDefault();
      try {
        setIsSearchingLogs(true);
        if (logs.length) {
        } else {
          const jsonLogs = await searchLogsPromise(0, form);
          if (jsonLogs.status === 200) {
            const jsonRecords = await searchTotalLogRecords(form);
            if (jsonRecords.status === 200) {
              setLogs(jsonLogs.object);
              pagination.setTotalRecords(jsonRecords.object.total);
            } else if (jsonRecords.status === 500) {
              Modal.error(jsonRecords.message, jsonRecords.object);
            }
          } else if (jsonLogs.status === 500) {
            Modal.error(jsonLogs.message, jsonLogs.object);
          }
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        setIsSearchingLogs(false);
      }
    },
    [Modal, logs, pagination, searchLogsPromise, searchTotalLogRecords]
  );

  React.useEffect(() => {
    SearchLogAreas();
  }, []); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit((form) => searchLogs(e, form))();
        }}
      >
        <div className={styles.userContainer}>
          <Controller
            name="user"
            control={control}
            rules={{
              required: "Selecione um usuário para buscar os logs",
            }}
            render={({ field }) => {
              return (
                <>
                  <label className={`label`}>Usuário</label>
                  <Select
                    placeholder="Pesquise um usuário..."
                    options={optionsUser.map((option) => {
                      return { value: { ...option }, label: `${option.idUsuario} | ${option.nome}` };
                    })}
                    {...field}
                    onChange={(e) =>
                      handleChangeForm(
                        e,
                        field.onChange,
                        "Ao alterar o usuário, os logs deverão ser consultados novamente.<br>Deseja Continuar?"
                      )
                    }
                    onInputChange={(value) => searchUsers(value)}
                    error={errors.user?.message}
                    noOptionsMessage={() => "Nenhum usuário encontrado"}
                    loadingMessage={() => "Buscando..."}
                    isLoading={isSearchingOptionsUser}
                    isDisabled={isSearchingLogs}
                  />
                </>
              );
            }}
          />
        </div>
        <div className={styles.systemContainer}>
          <div>
            <Controller
              name="logDescription"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <label htmlFor="logDescription" className="label">
                      Descrição Log
                    </label>
                    <Input
                      type="text"
                      placeholder="Digite a descrição do log"
                      id="logDescription"
                      {...field}
                      onChange={(e) =>
                        handleChangeForm(
                          e,
                          field.onChange,
                          "Ao alterar a descrição, os logs deverão ser consultados novamente.<br>Deseja Continuar?"
                        )
                      }
                      autoComplete="off"
                      error={errors.logDescription?.message}
                      disabled={isSearchingLogs}
                    />
                  </>
                );
              }}
            />
          </div>
        </div>
        <div className={styles.dataContainer}>
          <div>
            <Controller
              name="area"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <label className={`label`}>Área do Sistema (Opcional)</label>
                    <Select
                      placeholder="Selecione uma área do sistema"
                      options={optionsArea.map((option) => {
                        return { value: { ...option }, label: option.area };
                      })}
                      {...field}
                      onChange={(e) =>
                        handleChangeForm(
                          e,
                          field.onChange,
                          "Ao alterar a área, os logs deverão ser consultados novamente.<br>Deseja Continuar?"
                        )
                      }
                      noOptionsMessage={() => "Nenhuma área encontrada"}
                      loadingMessage={() => "Buscando..."}
                      error={errors.area?.message}
                      isLoading={isSearchingOptionsArea}
                      isDisabled={isSearchingLogs}
                    />
                  </>
                );
              }}
            />
          </div>
          <fieldset className={styles.dateFields}>
            <div>
              <Controller
                name="initialDate"
                control={control}
                rules={{
                  required: "Selecione a data inicial",
                }}
                render={({ field }) => {
                  return (
                    <>
                      <label className={`label`}>Data Inicial</label>
                      <Input
                        type="date"
                        {...field}
                        onChange={(e) =>
                          handleChangeForm(
                            e,
                            field.onChange,
                            "Ao alterar a data inicial, os logs deverão ser consultados novamente.<br>Deseja Continuar?"
                          )
                        }
                        error={errors.initialDate?.message}
                        disabled={isSearchingLogs}
                      />
                    </>
                  );
                }}
              />
            </div>
            <div>
              <Controller
                name="finalDate"
                control={control}
                rules={{
                  required: "Selecione a data final",
                }}
                render={({ field }) => {
                  return (
                    <>
                      <label className={`label`}>Data Final</label>
                      <Input
                        type="date"
                        {...field}
                        onChange={(e) =>
                          handleChangeForm(
                            e,
                            field.onChange,
                            "Ao alterar a data inicial, os logs deverão ser consultados novamente.<br>Deseja Continuar?"
                          )
                        }
                        error={errors.finalDate?.message}
                        disabled={isSearchingLogs}
                      />
                    </>
                  );
                }}
              />
            </div>
          </fieldset>
        </div>
        <div className={styles.buttonContainer}>
          <Button disabled={isSearchingLogs || logs.length}>
            {isSearchingLogs ? "Consultando Logs" : "Consultar Logs"}
          </Button>
        </div>
      </form>
      <section className={styles.logsSection}>
        {logs.length && !isSearchingLogs ? (
          <Table
            containerClassName={styles.TableLogsContainer}
            title={`Logs de Usuário<br>${user.value.nome}`}
            responsiveType="HeaderOnLeft"
          >
            <THead>
              <Tr>
                <Th>Área</Th>
                <Th>Ação</Th>
                <Th>Data</Th>
              </Tr>
            </THead>
            <TBody>
              {logs.map((log, index) => {
                return (
                  <Tr key={index}>
                    <Td heading="Área">{log.logarea.area}</Td>
                    <Td heading="Ação">
                      <LogMessage log={log.log.log} />
                    </Td>
                    <Td heading="Data">{formatDate(log.log.data, "dd/MM/yy às hh:mm:ss")}</Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : isSearchingLogs ? (
          <div className={styles.searchingLogsLoadingContainer}>
            <Circle size={100} />
          </div>
        ) : null}
        {logs.length ? (
          <Paginate
            maxItems={pagination.maxItems}
            totalRecords={pagination.totalRecords}
            currentPage={pagination.currentPage}
            setCurrentPage={pagination.setCurrentPage}
            onPageChange={(page) => handleSubmit((form) => onPageChange(page, form))()}
            isDisabled={isSearchingLogs}
          />
        ) : null}
      </section>
    </div>
  );
}
