import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "./contexts/modal";
import { AuthProvider } from "./contexts/auth";
import { AutoLogoutProvider } from "./contexts/autoLogout/autoLogout";
import { ChangesProvider } from "./contexts/changes";
import { Router } from "./routes/Router";

import "./styles/_global.css";
import React from "react";
import { notifications } from "./specials/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  // async function askUserPermission() {
  //   return await Notification.requestPermission();
  // }

  // async function createNotificationSubscription() {
  //   //aguarde a instalação do service worker estar pronta
  //   const serviceWorker = await navigator.serviceWorker.ready;
  //   // assinar e retornar a assinatura
  //   return await serviceWorker.pushManager.subscribe({
  //     userVisibleOnly: true,
  //     applicationServerKey: "BA3asJobXgcQZNqoCCeV9br9CC67dxN-s1frlyfA3DiZi0cVATcFyGoQ5JSrYiPMUQ65v8e1j9shL4nezmRkURg",
  //   });
  // }

  // React.useEffect(() => {
  //   async function registerUser() {
  //     console.log("Urangutango");
  //     await askUserPermission();
  //     const usuario = (await createNotificationSubscription()) || "";
  //     console.log(usuario);
  //     const response = await fetch("/test/add-inscricao", {
  //       method: "POST",
  //       body: JSON.stringify(usuario),
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //     });
  //     console.log(await response.json());
  //   }

  //   registerUser();
  // }, []);

  React.useEffect(() => {
    // async function sendNotifications() {
    //   const response = await fetch("/test/send-notifications", {
    //     method: "GET",
    //   });
    //   console.log(await response.json());
    // }
    // setInterval(() => {
    //   sendNotifications();
    // }, 5000);
  }, []);

  notifications.start();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ModalProvider>
            <AuthProvider>
              <ChangesProvider>
                <AutoLogoutProvider>
                  <Router />
                </AutoLogoutProvider>
              </ChangesProvider>
            </AuthProvider>
          </ModalProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
