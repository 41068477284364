import { useNavigate } from "react-router-dom";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { usePreviousRoute } from "../../../../../hooks/navigation";
import { useForm, useSelect } from "../../../../../hooks/form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Sistema } from "../../Sistemas/hooks/useSistemas";
import { isValid } from "../../../../../helpers/validations";
import { Tipo } from "../../Tipos/hooks/useTipos";

type permissoesAgrupadas = {
  [sistema: string]: {
    [tipo: string]: {
      permissoes: Permissao[];
    };
  };
};

export type Permissao = {
  sistema: {
    id: number;
    nome: string;
  };
  tipo: {
    id: number;
    nome: string;
  };
  permissao: {
    id: number;
    nome: string;
    descricao: string;
    status: Status;
  };
};

type Status = "A" | "I";

export default function usePermissoes() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const previousRoute = usePreviousRoute();
  const navigate = useNavigate();

  const [permissaoSelecionada, setPermissaoSelecionada] =
    useState<Permissao | null>(null);

  const [permissoes, setPermissoes] = useState<Permissao[]>([]);
  const permissoesAgrupadas = useMemo<permissoesAgrupadas>(() => {
    const agrupadas: permissoesAgrupadas = {};

    permissoes.forEach((permissao) => {
      if (!(permissao.sistema.nome in agrupadas)) {
        agrupadas[permissao.sistema.nome] = {};
      }

      if (!(permissao.tipo.nome in agrupadas[permissao.sistema.nome])) {
        agrupadas[permissao.sistema.nome][permissao.tipo.nome] = {
          permissoes: [],
        };
      }

      agrupadas[permissao.sistema.nome][permissao.tipo.nome].permissoes.push(
        permissao
      );
    });

    return agrupadas;
  }, [permissoes]);

  const sistema = useSelect<Sistema>({ type: "single", required: true });
  const [sistemaOptions, setSistemaOptions] = useState<
    ISelectOption<Sistema>[]
  >([]);

  const tipo = useSelect<Tipo>({ type: "single", required: true });
  const [tipoOptions, setTipoOptions] = useState<ISelectOption<Tipo>[]>([]);

  const nomePermissao = useForm({ required: true });
  const descricaoPermissao = useForm({ required: true });

  const status = useSelect<Status>({
    required: true,
    type: "single",
    defaultValue: { label: "Ativo", value: "A" },
  });
  const statusOptions = useMemo<ISelectOption<Status>[]>(
    () => [
      {
        label: "Ativo",
        value: "A",
      },
      { label: "Inativo", value: "I" },
    ],
    []
  );

  const buscandoSistemas = useLoading();

  const buscarSistemas = useCallback(async () => {
    try {
      buscandoSistemas.setLoading(true);
      const json = (await customFetch(
        "/admin/buscarSistemas",
        {}
      )) as DefaultFetchResponse<Sistema[]>;
      if (json.status === 200) {
        const options = json.object.map((sistema) => ({
          value: sistema,
          label: sistema.nome,
        }));
        setSistemaOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setSistemaOptions([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      buscandoSistemas.setLoading(false);
    }
  }, [Modal, buscandoSistemas, customFetch]);

  const buscandoTipos = useLoading();

  const buscarTipos = useCallback(
    async (sistemaId: number) => {
      try {
        buscandoTipos.setLoading(true);
        const json = (await customFetch("/admin/buscarTipos", {
          body: { sistemaId },
        })) as DefaultFetchResponse<Tipo[]>;
        if (json.status === 200) {
          const options = json.object
            .filter((tipo) => tipo.status === "A")
            .map((tipo) => ({
              value: tipo,
              label: tipo.nome,
            }));
          setTipoOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setTipoOptions([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        buscandoTipos.setLoading(false);
      }
    },
    [Modal, buscandoTipos, customFetch]
  );

  const buscandoPermissoes = useLoading();

  const buscarPermissoes = useCallback(async () => {
    try {
      buscandoPermissoes.setLoading(true);
      const json = (await customFetch(
        "/admin/buscarPermissoes",
        {}
      )) as DefaultFetchResponse<Permissao[]>;
      if (json.status === 200) {
        setPermissoes(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setPermissoes([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      buscandoPermissoes.setLoading(false);
    }
  }, [Modal, buscandoPermissoes, customFetch]);

  const salvandoAlteracoesPermissao = useLoading();

  const salvarAlteracoesPermissao = useCallback(
    async (idPermissao?: number) => {
      if (!isValid(sistema, tipo, nomePermissao, descricaoPermissao)) return;
      try {
        salvandoAlteracoesPermissao.setLoading(true);
        const json = (await customFetch("/admin/inserirAtualizarPermissao", {
          body: {
            id: idPermissao,
            nome: nomePermissao.value,
            tipoId: tipo.value?.value.id,
            status: status.value?.value,
            descricao: descricaoPermissao.value,
          },
        })) as DefaultFetchResponse<any>;
        if (json.status === 200) {
          buscarPermissoes();
          await Modal.success(json.message);
          navigate(previousRoute);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        salvandoAlteracoesPermissao.setLoading(false);
      }
    },
    [
      Modal,
      buscarPermissoes,
      customFetch,
      descricaoPermissao,
      navigate,
      nomePermissao,
      previousRoute,
      salvandoAlteracoesPermissao,
      sistema,
      status.value?.value,
      tipo,
    ]
  );

  const editarPermissao = useCallback(
    (permissao: Permissao) => {
      setPermissaoSelecionada(permissao);
      navigate("atualizar");
    },
    [navigate]
  );

  const resetForm = useCallback(() => {
    sistema.reset();
    tipo.reset();
    status.reset();
    nomePermissao.reset();
    descricaoPermissao.reset();
    setPermissaoSelecionada(null);
  }, [sistema, tipo, status, nomePermissao, descricaoPermissao]);

  useEffect(() => {
    buscarSistemas();
    buscarPermissoes();
  }, []); // eslint-disable-line

  return {
    tipo,
    tipoOptions,
    setTipoOptions,
    sistema,
    sistemaOptions,
    nomePermissao,
    descricaoPermissao,
    status,
    statusOptions,
    permissaoSelecionada: {
      value: permissaoSelecionada,
      setValue: setPermissaoSelecionada,
    },
    permissoesAgrupadas,
    buscarTipos: {
      buscar: buscarTipos,
      loading: buscandoTipos.isLoading,
    },
    buscarSistemas: {
      buscar: buscarSistemas,
      loading: buscandoSistemas.isLoading,
    },
    buscarPermissoes: {
      buscar: buscarPermissoes,
      loading: buscandoPermissoes.isLoading,
    },
    salvarAlteracoesPermissao: {
      salvar: salvarAlteracoesPermissao,
      loading: salvandoAlteracoesPermissao.isLoading,
    },
    editarPermissao,
    resetForm,
  };
}

export type UsePermissoes = ReturnType<typeof usePermissoes>;
