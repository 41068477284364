import React, { useEffect } from "react";
import { Button, LinkButton } from "../../../../../components/Form";
import { Circle } from "../../../../../components/Loading";
import { UsePermissoes } from "../hooks/usePermissoes";

import styles from "./Lista.module.css";
import { Collapse } from "../../../../../components/Collapse/Collapse";
import { Pencil } from "phosphor-react";

interface Props {
  data: UsePermissoes;
}

export default function Lista({ data }: Props) {
  useEffect(() => {
    data.resetForm();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <nav className={styles.navContainer}>
        <LinkButton to={"novo"}>Nova Permissão</LinkButton>
      </nav>
      <span className="separator" />
      <div>
        {Object.values(data.permissoesAgrupadas).length > 0 &&
        !data.buscarPermissoes.loading ? (
          <ul className={styles.permissionGroups}>
            {Object.keys(data.permissoesAgrupadas).map((keySistema) => (
              <li key={keySistema}>
                <Collapse
                  label={<h3 className={styles.systemTitle}>{keySistema}</h3>}
                  iconColor={"var(--gray-8)"}
                  key={undefined}
                  className={undefined}
                  classNameMainContainer={undefined}
                  classNameButton={undefined}
                >
                  <span className="separator" />
                  <ul className={styles.permissionGroups}>
                    {Object.keys(data.permissoesAgrupadas[keySistema]).map(
                      (keyTipo) => (
                        <li key={keyTipo}>
                          <h4 className={styles.permissionGroupsTitle}>
                            {keyTipo}
                          </h4>
                          <ul className={styles.permissions}>
                            {data.permissoesAgrupadas[keySistema][
                              keyTipo
                            ].permissoes.map((perm) => (
                              <li key={perm.permissao.id}>
                                <p>{perm.permissao.id}</p>
                                <span
                                  className={styles.permissionInfoSeparator}
                                />
                                <div className={styles.permissionInfoData}>
                                  <p className={styles.permissionInfoName}>
                                    {perm.permissao.nome}{" "}
                                    {perm.permissao.status === "I" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        (INATIVA)
                                      </span>
                                    ) : null}
                                  </p>
                                  <p className={styles.permissionInfoObs}>
                                    {perm.permissao.descricao}
                                  </p>
                                </div>
                                <span
                                  className={styles.permissionInfoSeparator}
                                />
                                <Button
                                  className={`${styles.permissionInfoButton}`}
                                  onClick={() => {
                                    data.editarPermissao(perm);
                                  }}
                                  variant="edit"
                                >
                                  <Pencil weight="fill" />
                                </Button>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )
                    )}
                  </ul>
                </Collapse>
              </li>
            ))}
          </ul>
        ) : data.buscarPermissoes.loading ? (
          <div
            className="loadingContainer"
            style={{ height: "500px", justifyContent: "center" }}
          >
            <Circle size={100} />
          </div>
        ) : (
          <p className="lineCardMessage">Nenhuma Permissão Encontrada</p>
        )}
      </div>
    </div>
  );
}
