import React, { forwardRef } from "react";

import ReactSelect, { ActionMeta } from "react-select";
import styles from "./Select.module.css";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

interface CustomStyle extends React.CSSProperties {
  [key: string]: any;
}

type Value<S = any> = { label: string | React.ReactNode; value: S };
export type MultipleSelectValueType<S = any> = Value<S>[];
export type SingleSelectValueType<S = any> = Value<S> | null;

interface ICustomSelect extends StateManagerProps {
  value: SingleSelectValueType | MultipleSelectValueType;
  defaultValue?: SingleSelectValueType | MultipleSelectValueType;
  options: Value[] | any;
  isMulti?: boolean;
  selectContainerId?: string;
  error?: string | null;
  defaultBorderColor?: string;
  noOptionsMessage?: any;
  loadingMessage?: any;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
}

const isValueType = (test: any): test is Value => {
  return test && test.value !== undefined && test.label !== undefined;
};

export const Select = forwardRef(
  (
    {
      id = "",
      selectContainerId = "",
      value,
      defaultValue,
      error,
      blurInputOnSelect = false,
      closeMenuOnSelect = true,
      menuPlacement = "auto",
      noOptionsMessage = () => "Sem opções",
      loadingMessage = () => "Carregando...",
      onKeyDown = (e) => {},
      onInputChange = (value, actionMeta) => {},
      isClearable = true,
      isSearchable = true,
      isLoading = false,
      isMulti = false,
      isDisabled = false,
      defaultBorderColor = "#00ff15",
      ...props
    }: ICustomSelect,
    ref
  ) => {
    const selectRef = React.useRef<any>();

    const isFilled = React.useCallback(() => {
      if (isMulti) {
        if (Array.isArray(value)) return !!value?.length;
        else return false;
      } else {
        if (isValueType(value)) return !!(value.value !== undefined && value.value !== null);
        else return false;
      }
    }, [isMulti, value]);

    const classStates = `${isSearchable ? "isSearchable" : ""} ${isFilled() ? "isFilled" : ""} ${
      error ? "isInvalid" : ""
    } ${isDisabled ? "isDisabled" : ""}`.trim();

    return (
      <div style={{ "--default-border-color": defaultBorderColor } as CustomStyle}>
        <ReactSelect
          ref={selectRef}
          classNamePrefix={`${classStates} ${styles["react-select"]}`}
          inputId={id}
          id={selectContainerId}
          value={value}
          defaultValue={defaultValue}
          blurInputOnSelect={blurInputOnSelect}
          closeMenuOnSelect={closeMenuOnSelect}
          isMulti={isMulti}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isLoading={isLoading}
          menuPlacement={menuPlacement}
          noOptionsMessage={() => {
            if (noOptionsMessage.constructor.name === "Function") {
              return noOptionsMessage();
            } else {
              return noOptionsMessage;
            }
          }}
          loadingMessage={() => {
            if (loadingMessage.constructor.name === "Function") {
              return loadingMessage();
            } else {
              return noOptionsMessage;
            }
          }}
          onKeyDown={(e) => {
            if (onKeyDown) {
              onKeyDown(e);
            }
          }}
          onInputChange={(value, actionMeta) => {
            const acceptedActions = actionMeta.action === "input-change" || actionMeta.action === "set-value";
            if (acceptedActions && onInputChange) {
              onInputChange(value, actionMeta);
            }
          }}
          isDisabled={isDisabled}
          {...props}
          options={isLoading ? [] : props.options}
        />
        {error && !isDisabled && <p className={styles.error}>{error}</p>}
      </div>
    );
  }
);
