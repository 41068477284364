import { useCallback, useEffect } from "react";
import { useChanges, useModal } from "../../../../hooks/contexts";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useFetch from "../../../../api/hooks/useFetch";
import { PedidosApi } from "../../../../api/constants";

type User = PedidosApi.BuscarUsuarios.Usuario;
type Seller = PedidosApi.BuscarVendedoresAtivosInativosTabletUsuario.Vendedor;
type Enterprise = PedidosApi.BuscarEmpresasAtivasInativasTabletUsuario.Empresa;
type Supervisor = PedidosApi.BuscarSupervisores.Supervisor;
type PaymentCondition = PedidosApi.BuscarCondicoesPagamento.CondicaoPagamento;
type Operation = PedidosApi.BuscarOperacoes.Operacao;
type ValidityTable = PedidosApi.BuscarTabelasVigencia.TabelaVigencia;
type Deposit = PedidosApi.BuscarDepositos.Deposito;

type UserForm = {
  user: ISelectOption<User> | null;
};

type GeralForm = {
  host: string;
  port: string;
  sellers: ISelectOption<Seller>[];
  enterprises: ISelectOption<Enterprise>[];
};

type AduboForm = {
  verifyCustomerContract: boolean;
  defaultSeller: ISelectOption<Seller> | null;
  defaultSupervisor: ISelectOption<Supervisor> | null;
  defaultPaymentCondition: ISelectOption<PaymentCondition> | null;
  defaultOperation: ISelectOption<Operation> | null;
  defaultValidityTable: ISelectOption<ValidityTable> | null;
  defaultEnterprise: ISelectOption<Enterprise> | null;
  defaultDeposit: ISelectOption<Deposit> | null;
};

type SementeDefensivoForm = {
  active: boolean;
  defaultSeller: ISelectOption<Seller> | null;
  defaultSupervisor: ISelectOption<Supervisor> | null;
  defaultOperation: ISelectOption<Operation> | null;
  defaultEnterprise: ISelectOption<Enterprise> | null;
  defaultDeposit: ISelectOption<Deposit> | null;
};

type FoliarForm = {
  defaultSeller: ISelectOption<Seller> | null;
  defaultSupervisor: ISelectOption<Supervisor> | null;
  defaultOperation: ISelectOption<Operation> | null;
  defaultEnterprise: ISelectOption<Enterprise> | null;
};

export default function useTabletSettings() {
  const Changes = useChanges();
  const Modal = useModal();

  const queryClient = useQueryClient();

  const userForm = useForm<UserForm>({ mode: "onChange" });

  const user = userForm.watch("user");

  const geralForm = useForm<GeralForm>({
    mode: "onChange",
  });

  const aduboForm = useForm<AduboForm>({
    mode: "onChange",
  });

  const sementeDefensivoForm = useForm<SementeDefensivoForm>({
    mode: "onChange",
  });

  const foliarForm = useForm<FoliarForm>({
    mode: "onChange",
  });

  const searchUsers = useFetch(PedidosApi.BuscarUsuarios.ApiInfo, {
    select: (data) =>
      data.object.map((option) => ({
        label: `${option.idUsuario} | ${option.nome}`,
        value: option,
      })),
  });

  const { axiosRequest: requestUserSellers } = useFetch(PedidosApi.BuscarVendedoresAtivosInativosTabletUsuario.ApiInfo);

  const sellersQuery = useQuery({
    enabled: user !== null,
    queryKey: ["active-inactive-user-sellers"],
    queryFn: () => requestUserSellers({ idUsuario: user!.value.idUsuario }),
    networkMode: "always",
    select: (data) =>
      data.data.object.map((option) => {
        return {
          label: `${option.idVendedor} | ${option.nome}`,
          value: option,
        };
      }),
  });

  const { axiosRequest: requestUserEnterprises } = useFetch(
    PedidosApi.BuscarEmpresasAtivasInativasTabletUsuario.ApiInfo
  );

  const enterprisesQuery = useQuery({
    enabled: user !== null,
    queryKey: ["active-inactive-user-enterprises"],
    queryFn: () => requestUserEnterprises({ idUsuario: user!.value.idUsuario }),
    networkMode: "always",
    select: (data) =>
      data.data.object.map((option) => {
        return {
          label: `${option.idEmpresa} | ${option.nomeEmpresa}`,
          value: option,
        };
      }),
  });

  const { axiosRequest: requestSupervisors } = useFetch(PedidosApi.BuscarSupervisores.ApiInfo);

  const supervisorsQuery = useQuery({
    queryKey: ["supervisors"],
    queryFn: () => requestSupervisors(),
    networkMode: "always",
    select: (data) =>
      data.data.object.map((option) => {
        return {
          label: `${option.idSupervisor} | ${option.nome}`,
          value: option,
        };
      }),
  });

  const { axiosRequest: requestPaymentConditions } = useFetch(PedidosApi.BuscarCondicoesPagamento.ApiInfo);

  const paymentConditionsQuery = useQuery({
    queryKey: ["payment-conditions"],
    queryFn: () => requestPaymentConditions(),
    networkMode: "always",
    select: (data) =>
      data.data.object.map((option) => {
        return {
          label: `${option.idCondicaoPagamento} | ${option.descricao}`,
          value: option,
        };
      }),
  });

  const { axiosRequest: requestOperations } = useFetch(PedidosApi.BuscarOperacoes.ApiInfo);

  const operationsQuery = useQuery({
    queryKey: ["operations"],
    queryFn: () => requestOperations(),
    networkMode: "always",
    select: (data) =>
      data.data.object.map((option) => {
        return {
          label: `${option.id} | ${option.nome}`,
          value: option,
        };
      }),
  });

  const { axiosRequest: requestValidityTables } = useFetch(PedidosApi.BuscarTabelasVigencia.ApiInfo);

  const validityTablesQuery = useQuery({
    queryKey: ["validity-tables"],
    queryFn: () => requestValidityTables(),
    networkMode: "always",
    select: (data) =>
      data.data.object.map((option) => {
        return {
          label: `${option.idVigencia} | ${option.nome}`,
          value: option,
        };
      }),
  });

  const { axiosRequest: requestDeposits } = useFetch(PedidosApi.BuscarDepositos.ApiInfo);

  const depositsQuery = useQuery({
    queryKey: ["deposits"],
    queryFn: () => requestDeposits(),
    networkMode: "always",
    select: (data) =>
      data.data.object.map((option) => {
        return {
          label: `${option.idDeposito} | ${option.descricao}`,
          value: option,
        };
      }),
  });

  const { axiosRequest: requestUserTabletSettings } = useFetch(PedidosApi.BuscarConfiguracoesTabletUsuario.ApiInfo);

  const userTabletSettingsQuery = useQuery({
    enabled: user !== null,
    queryKey: ["user-tablet-settings", user?.value.idUsuario],
    queryFn: () => requestUserTabletSettings({ idUsuario: user!.value.idUsuario }),
    networkMode: "always",
    select: (data) => {
      if (data.data.object) {
        const formattedData: {
          configuracaoTabletVendedor: GeralForm["sellers"];
          configuracaoTabletEmpresa: GeralForm["enterprises"];
        } & typeof data.data.object = {
          ...JSON.parse(JSON.stringify(data.data.object)),
          configuracaoTabletVendedor: data.data.object?.configuracaoTabletVendedor.map((x) => ({
            label: `${x.idVendedor} | ${x.vendedor.nome}`,
            value: { idVendedor: x.idVendedor, nome: x.vendedor.nome, situacao: "A" as "A" },
          })),
          configuracaoTabletEmpresa: data.data.object?.configuracaoTabletEmpresa.map((x) => ({
            label: `${x.idEmpresa} | ${x.empresa.nomeEmpresa}`,
            value: {
              idEmpresa: x.idEmpresa,
              nomeEmpresa: x.empresa.nomeEmpresa,
              nomeFantasia: x.empresa.nomeFantasia,
              situacao: "A" as "A",
            },
          })),
        };

        return formattedData;
      }
      return data.data.object;
    },
  });

  const saveUserGeneralTabletSettings = useFetch(PedidosApi.SalvarConfiguracoesGeraisTabletUsuario.ApiInfo, {
    onSuccess(response) {
      Changes.reset();
      queryClient.invalidateQueries({
        queryKey: ["user-tablet-settings"],
      });
      Modal.success(response.message);
    },
    onError(error) {
      Modal.error(error.response?.data.message, error.response?.data.object);
    },
  });

  const saveUserAduboTabletSettings = useFetch(PedidosApi.SalvarConfiguracoesAduboTabletUsuario.ApiInfo, {
    onSuccess(response) {
      Changes.reset();
      queryClient.invalidateQueries({
        queryKey: ["user-tablet-settings"],
      });
      Modal.success(response.message);
    },
    onError(error) {
      Modal.error(error.response?.data.message, error.response?.data.object);
    },
  });

  const saveUserSementeDefensivoTabletSettings = useFetch(
    PedidosApi.SalvarConfiguracoesSementeDefensivoTabletUsuario.ApiInfo,
    {
      onSuccess(response) {
        Changes.reset();
        queryClient.invalidateQueries({
          queryKey: ["user-tablet-settings"],
        });
        Modal.success(response.message);
      },
      onError(error) {
        Modal.error(error.response?.data.message, error.response?.data.object);
      },
    }
  );

  const saveUserFoliarTabletSettings = useFetch(PedidosApi.SalvarConfiguracoesFoliarTabletUsuario.ApiInfo, {
    onSuccess(response) {
      Changes.reset();
      queryClient.invalidateQueries({
        queryKey: ["user-tablet-settings"],
      });
      Modal.success(response.message);
    },
    onError(error) {
      Modal.error(error.response?.data.message, error.response?.data.object);
    },
  });

  const populateGeralForm = useCallback((data: typeof userTabletSettingsQuery.data) => {
    if (data) {
      geralForm.setValue("port", data.porta);
      geralForm.setValue("host", data.ipHost);
      geralForm.setValue("sellers", data.configuracaoTabletVendedor);
      geralForm.setValue("enterprises", data.configuracaoTabletEmpresa);
      geralForm.reset(geralForm.getValues());
    }
  }, []); // eslint-disable-line

  const populateAduboForm = useCallback(
    (data: PedidosApi.BuscarConfiguracoesTabletUsuario.ConfiguracaoTabletAdubo | null) => {
      if (data) {
        aduboForm.setValue("verifyCustomerContract", data.verificaClienteContrato === "S");
        if (data.vendedor)
          aduboForm.setValue("defaultSeller", {
            label: `${data.vendedor.idVendedor} | ${data.vendedor.nome}`,
            value: { idVendedor: data.vendedor.idVendedor, nome: data.vendedor.nome, situacao: "A" as "A" },
          });
        if (data.supervisor)
          aduboForm.setValue("defaultSupervisor", {
            label: `${data.supervisor.idSupervisor} | ${data.supervisor.nome}`,
            value: { idSupervisor: data.supervisor.idSupervisor, nome: data.supervisor.nome, situacao: "A" as "A" },
          });
        if (data.condicaoPagamento)
          aduboForm.setValue("defaultPaymentCondition", {
            label: `${data.condicaoPagamento.idCondicaoPagamento} | ${data.condicaoPagamento.descricao}`,
            value: {
              idCondicaoPagamento: data.condicaoPagamento.idCondicaoPagamento,
              descricao: data.condicaoPagamento.descricao,
              situacao: "A" as "A",
              prazo: null,
            },
          });
        if (data.operacao)
          aduboForm.setValue("defaultOperation", {
            label: `${data.operacao.idOperacao} | ${data.operacao.descricao}`,
            value: {
              id: data.operacao.idOperacao,
              nome: data.operacao.descricao,
              situacao: "A" as "A",
            },
          });
        if (data.tabela)
          aduboForm.setValue("defaultValidityTable", {
            label: `${data.tabela.idVigencia} | ${data.tabela.nome}`,
            value: {
              idVigencia: data.tabela.idVigencia,
              nome: data.tabela.nome,
            },
          });
        if (data.empresa)
          aduboForm.setValue("defaultEnterprise", {
            label: `${data.empresa.idEmpresa} | ${data.empresa.nomeEmpresa}`,
            value: {
              idEmpresa: data.empresa.idEmpresa,
              nomeEmpresa: data.empresa.nomeEmpresa,
              nomeFantasia: data.empresa.nomeFantasia,
              situacao: "A",
            },
          });
        if (data.deposito)
          aduboForm.setValue("defaultDeposit", {
            label: `${data.deposito.idDeposito} | ${data.deposito.descricao}`,
            value: {
              idDeposito: data.deposito.idDeposito,
              descricao: data.deposito.descricao,
              status: "A",
            },
          });

        aduboForm.reset(aduboForm.getValues());
      }
    },
    [] // eslint-disable-line
  );

  const populateSementeDefensivoForm = useCallback(
    (data: PedidosApi.BuscarConfiguracoesTabletUsuario.ConfiguracaoTabletSementeDefensivo | null) => {
      if (data) {
        sementeDefensivoForm.setValue("active", data.statusConfigTabletSementeDefensivo === "S");
        if (data.vendedor)
          sementeDefensivoForm.setValue("defaultSeller", {
            label: `${data.vendedor.idVendedor} | ${data.vendedor.nome}`,
            value: { idVendedor: data.vendedor.idVendedor, nome: data.vendedor.nome, situacao: "A" as "A" },
          });
        if (data.supervisor)
          sementeDefensivoForm.setValue("defaultSupervisor", {
            label: `${data.supervisor.idSupervisor} | ${data.supervisor.nome}`,
            value: { idSupervisor: data.supervisor.idSupervisor, nome: data.supervisor.nome, situacao: "A" as "A" },
          });
        if (data.operacao)
          sementeDefensivoForm.setValue("defaultOperation", {
            label: `${data.operacao.idOperacao} | ${data.operacao.descricao}`,
            value: {
              id: data.operacao.idOperacao,
              nome: data.operacao.descricao,
              situacao: "A" as "A",
            },
          });
        if (data.empresa)
          sementeDefensivoForm.setValue("defaultEnterprise", {
            label: `${data.empresa.idEmpresa} | ${data.empresa.nomeEmpresa}`,
            value: {
              idEmpresa: data.empresa.idEmpresa,
              nomeEmpresa: data.empresa.nomeEmpresa,
              nomeFantasia: data.empresa.nomeFantasia,
              situacao: "A",
            },
          });
        if (data.deposito)
          sementeDefensivoForm.setValue("defaultDeposit", {
            label: `${data.deposito.idDeposito} | ${data.deposito.descricao}`,
            value: {
              idDeposito: data.deposito.idDeposito,
              descricao: data.deposito.descricao,
              status: "A",
            },
          });

        sementeDefensivoForm.reset(sementeDefensivoForm.getValues());
      }
    },
    [] // eslint-disable-line
  );

  const populateFoliarForm = useCallback(
    (data: PedidosApi.BuscarConfiguracoesTabletUsuario.ConfiguracaoTabletFoliar | null) => {
      if (data) {
        if (data.vendedor)
          foliarForm.setValue("defaultSeller", {
            label: `${data.vendedor.idVendedor} | ${data.vendedor.nome}`,
            value: { idVendedor: data.vendedor.idVendedor, nome: data.vendedor.nome, situacao: "A" as "A" },
          });
        if (data.supervisor)
          foliarForm.setValue("defaultSupervisor", {
            label: `${data.supervisor.idSupervisor} | ${data.supervisor.nome}`,
            value: { idSupervisor: data.supervisor.idSupervisor, nome: data.supervisor.nome, situacao: "A" as "A" },
          });
        if (data.operacao)
          foliarForm.setValue("defaultOperation", {
            label: `${data.operacao.idOperacao} | ${data.operacao.descricao}`,
            value: {
              id: data.operacao.idOperacao,
              nome: data.operacao.descricao,
              situacao: "A" as "A",
            },
          });
        if (data.empresa)
          foliarForm.setValue("defaultEnterprise", {
            label: `${data.empresa.idEmpresa} | ${data.empresa.nomeEmpresa}`,
            value: {
              idEmpresa: data.empresa.idEmpresa,
              nomeEmpresa: data.empresa.nomeEmpresa,
              nomeFantasia: data.empresa.nomeFantasia,
              situacao: "A",
            },
          });

        foliarForm.reset(foliarForm.getValues());
      }
    },
    [] // eslint-disable-line
  );

  const populateForms = useCallback(
    (data: typeof userTabletSettingsQuery.data) => {
      populateGeralForm(data);
      populateAduboForm(data?.configuracaoTabletAdubo ?? null);
      populateSementeDefensivoForm(data?.configuracaoTabletSementeDefensivo ?? null);
      populateFoliarForm(data?.configuracaoTabletFoliar ?? null);
    },
    [] // eslint-disable-line
  );

  const resetForms = useCallback(() => {
    geralForm.reset({
      host: "",
      port: "",
      sellers: [],
      enterprises: [],
    });

    aduboForm.reset({
      verifyCustomerContract: true,
      defaultSeller: null,
      defaultSupervisor: null,
      defaultPaymentCondition: null,
      defaultOperation: null,
      defaultValidityTable: null,
      defaultEnterprise: null,
      defaultDeposit: null,
    });

    sementeDefensivoForm.reset({
      active: true,
      defaultSeller: null,
      defaultSupervisor: null,
      defaultOperation: null,
      defaultEnterprise: null,
      defaultDeposit: null,
    });

    foliarForm.reset({
      defaultSeller: null,
      defaultSupervisor: null,
      defaultOperation: null,
      defaultEnterprise: null,
    });
  }, [aduboForm, foliarForm, geralForm, sementeDefensivoForm]);

  const resetUserForms = useCallback(() => {
    geralForm.reset();
    aduboForm.reset();
    foliarForm.reset();
    sementeDefensivoForm.reset();
  }, [aduboForm, foliarForm, geralForm, sementeDefensivoForm]);

  useEffect(() => {
    searchUsers.fetch({ descricao: "", quantidade: 100 });

    return () => {
      searchUsers.abort();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    resetForms();
  }, [user]); // eslint-disable-line

  useEffect(() => {
    populateForms(userTabletSettingsQuery.data);
  }, [userTabletSettingsQuery.data]); // eslint-disable-line

  return {
    user,
    changes: Changes,
    forms: {
      geralForm,
      aduboForm,
      sementeDefensivoForm,
      foliarForm,
      userForm,
    },
    queries: {
      searchUsers,
      sellersQuery,
      enterprisesQuery,
      userTabletSettingsQuery,
      supervisorsQuery,
      paymentConditionsQuery,
      operationsQuery,
      validityTablesQuery,
      depositsQuery,
      saveUserGeneralTabletSettings,
      saveUserAduboTabletSettings,
      saveUserSementeDefensivoTabletSettings,
      saveUserFoliarTabletSettings,
    },
    resetForms,
    resetUserForms,
  };
}

export type UseTabletSettings = ReturnType<typeof useTabletSettings>;
