import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { ITableData } from "../../../../../components/Data/XTable";
import { Button } from "../../../../../components/Form";
import { Pencil } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { usePreviousRoute } from "../../../../../hooks/navigation";

export type Sistema = {
  id: number;
  nome: string;
};

export default function useSistemas() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const previousRoute = usePreviousRoute();

  const navigate = useNavigate();

  const [sistemaSelecionado, setSistemaSelecionado] = useState<Sistema | null>(
    null
  );

  const [sistemas, setSistemas] = useState<Sistema[]>([]);
  const tableSistemas = useMemo<ITableData>(() => {
    const data = sistemas.map((sistema) => ({
      ...sistema,
      editar: (
        <>
          <Button
            type="button"
            variant="edit"
            onClick={() => {
              setSistemaSelecionado(sistema);
              navigate("atualizar");
            }}
            data-option-button
            style={{
              margin: "auto",
              padding: "0.5rem 0.75rem",
              minWidth: "100%",
              width: "1.25rem",
            }}
          >
            <Pencil weight="fill" />
          </Button>
        </>
      ),
    }));

    return {
      columns: [
        {
          title: "ID",
          objectName: "id",
          isSorted: false,
          style: { width: "10px" },
        },
        {
          title: "Sistema",
          objectName: "nome",
          isSorted: false,
        },
        {
          title: "",
          objectName: "editar",
          isSorted: false,
          style: { width: "80px" },
        },
      ],
      data: data,
    };
  }, [navigate, sistemas]);

  const buscandoSistemas = useLoading();
  const salvandoAlteracoes = useLoading();

  const buscarSistemas = useCallback(async () => {
    try {
      buscandoSistemas.setLoading(true);
      const json = (await customFetch(
        "/admin/buscarSistemas",
        {}
      )) as DefaultFetchResponse<Sistema[]>;
      if (json.status === 200) {
        setSistemas(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setSistemas([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      buscandoSistemas.setLoading(false);
    }
  }, [Modal, buscandoSistemas, customFetch]);

  const salvarAlteracoesSistema = useCallback(
    async (nomeSistema: string, idSistema?: number) => {
      try {
        salvandoAlteracoes.setLoading(true);
        const json = (await customFetch("/admin/inserirAtualizarSistema", {
          body: {
            id: idSistema,
            nome: nomeSistema,
          },
        })) as DefaultFetchResponse<any>;
        if (json.status === 200) {
          buscarSistemas();
          await Modal.success(json.message);
          navigate(previousRoute);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        salvandoAlteracoes.setLoading(false);
      }
    },
    [
      Modal,
      buscarSistemas,
      customFetch,
      navigate,
      previousRoute,
      salvandoAlteracoes,
    ]
  );

  useEffect(() => {
    buscarSistemas();
  }, []); // eslint-disable-line

  return {
    tableSistemas,
    buscarSistema: {
      buscar: buscarSistemas,
      loading: buscandoSistemas.isLoading,
    },
    sistemaSelecionado: {
      value: sistemaSelecionado,
      setValue: setSistemaSelecionado,
    },
    salvarAlteracoesSistema: {
      salvar: salvarAlteracoesSistema,
      loading: salvandoAlteracoes.isLoading,
    },
  };
}

export type UseSistemas = ReturnType<typeof useSistemas>;
