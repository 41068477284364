import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { ITableData } from "../../../../components/Data/XTable";
import { formatDate, formatMoney } from "../../../../helpers/format";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/Form";
import { Pencil } from "phosphor-react";
import { useForm } from "react-hook-form";

export type Pedido = {
  idPedido: number;
  valorBrutoPedido: number;
  valorLiquidoPedido: number;
  dataPedido: string;
  totalPremiacoes: number;
  totalBaixadoPremiacoes: number;
  cliente: ClientePedido;
  premiacoes: Premiacao[];
};

export interface ClientePedido {
  idCliente: number;
  nomeCliente: string;
  documento: string;
}

export type Premiacao = {
  idPremiacao: number;
  idEmpresa: number;
  idPedido: number;
  valorOriginal: number;
  descricao: string;
  valorSaldo: number;
  valorPago: number;
  tipo: string;
};

// TODAS, PENDENTES, BAIXADAS
export type EstadoPremiacoes = "T" | "P" | "B";

export type ResponseEmpresa = {
  empresas: Empresa[];
  empresaPadrao: number;
};

export type Empresa = {
  idEmpresa: number;
  idCidade: number;
  nomeEmpresa: string;
  cnpj: string;
  inscricaoEstadual: string;
  inscricaoMunicipal: string;
  endereco: string;
  numero: string;
  bairro: string;
  cep: string;
  telefone: string;
  complemento: string;
  nomeFantasia: string;
  situacao: string;
  razaoSocial: string;
  dataModificacao: string;
};

export type Cliente = {
  cliente: {
    idCliente: number;
    bairro: string;
    telefone: string;
    cep: string;
    cadastroPessoal: string;
    idCidade: number;
    endereco: string;
    nomeFantasia: string;
    numero: string;
    razaoSocial: string;
    sexo: string;
    telefone1: string;
    telefone2: string;
    tipoCliente: string;
    descricao: string;
    situacao: string;
    dataManutenção: string;
    flagCliente: string;
    dataNascimento: any;
    estadoCivil: any;
    idRegiao: any;
    creditoBloqueado: any;
    observacaoLimite: any;
    limiteVencimento: any;
    valorLimiteCredito: number;
  };
  cidade: {
    idCidade: number;
    idPais: number;
    nomeCidade: string;
    uf: string;
    cep: string;
    cepSecundario: string;
    situacao: string;
    idIBGE: string;
    dataModificacao: string;
    latitude: number;
    longitude: number;
  };
};

type Form = {
  idPedido: string;
  empresas: ISelectOption<Empresa>[];
  cliente: ISelectOption<Cliente> | null;
  dataInicial: string;
  dataFinal: string;
  estadoPremiacoes: ISelectOption<EstadoPremiacoes>;
};

export default function useBaixaManual() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const navigate = useNavigate();

  const [pedidoSelecionado, setPedidoSelecionado] = useState<Pedido | null>(null);

  const estadoPremiacoesOptions = useMemo<ISelectOption<EstadoPremiacoes>[]>(() => {
    return [
      {
        label: "Todas",
        value: "T",
      },
      {
        label: "Pendentes",
        value: "P",
      },
      {
        label: "Baixadas",
        value: "B",
      },
    ];
  }, []);

  const form = useForm<Form>({
    mode: "onChange",
    defaultValues: {
      estadoPremiacoes: estadoPremiacoesOptions[0],
    },
  });

  const [empresaOptions, setEmpresaOptions] = useState<ISelectOption<Empresa>[]>([]);
  const [clienteOptions, setClienteOptions] = useState<ISelectOption<Cliente>[]>([]);

  const [listaPedidos, setListaPedidos] = useState<Pedido[]>([]);

  const pedidosTableData = useMemo<ITableData>(() => {
    const data = listaPedidos.map((pedido) => ({
      ...pedido,
      nomeCliente: pedido.cliente.nomeCliente,
      realizarBaixas: (
        <Button
          type="button"
          variant="edit"
          onClick={() => {
            setPedidoSelecionado(pedido);
            navigate("realizar-baixas");
          }}
          style={{ minWidth: "100%", padding: "8.5px" }}
          data-option-button
        >
          <Pencil weight="fill" />
        </Button>
      ),
    }));

    return {
      columns: [
        {
          title: "ID",
          objectName: "idPedido",
          isSorted: true,
          style: { width: "10px" },
        },
        {
          title: "Cliente",
          objectName: "nomeCliente",
          isSorted: true,
        },
        {
          title: "Valor",
          objectName: "valorBrutoPedido",
          isSorted: true,
          formatting: (valor) => formatMoney(valor),
        },
        {
          title: "Data",
          objectName: "dataPedido",
          isSorted: true,
          formatting: (valor) => formatDate(valor, "dd/MM/yyyy hh:mm:ss"),
        },
        {
          title: "Premiação",
          objectName: "totalPremiacoes",
          isSorted: true,
          formatting: (valor) => formatMoney(valor),
        },
        {
          title: "Baixado",
          objectName: "totalBaixadoPremiacoes",
          isSorted: true,
          formatting: (valor) => formatMoney(valor),
        },
        {
          title: "",
          objectName: "realizarBaixas",
          isSorted: false,
          style: { width: "70px" },
        },
      ],
      data,
    };
  }, [listaPedidos, navigate]);

  const buscandoPedidosPremiacoes = useLoading();
  const buscandoEmpresas = useLoading();
  const buscandoClientes = useLoading();

  const buscarEmpresas = useCallback(async () => {
    try {
      buscandoEmpresas.setLoading(true);
      const json = (await customFetch("/premiations/manager/searchEnterprises", {
        body: {
          descricao: "",
        },
      })) as DefaultFetchResponse<ResponseEmpresa>;
      if (json.status === 200) {
        const options = json.object.empresas.map((option) => {
          return {
            value: option,
            label: `${option.idEmpresa} | ${option.nomeEmpresa}`,
          };
        });

        setEmpresaOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      buscandoEmpresas.setLoading(false);
    }
  }, [Modal, buscandoEmpresas, customFetch]);

  const buscarClientes = useCallback(
    async (description = "") => {
      try {
        buscandoClientes.setLoading(true);
        const json = (await customFetch("/premiations/manager/searchClients", {
          body: {
            descricao: description,
          },
        })) as DefaultFetchResponse<Cliente[]>;
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.cliente.idCliente} | ${option.cliente.razaoSocial} | ${option.cidade.nomeCidade}`,
              customLabel: (
                <div style={{ fontSize: "0.875rem" }} key={`${option.cliente.idCliente}-div`}>
                  <p key={`${option.cliente.idCliente}-p1`}>
                    {option.cliente.idCliente} | {option.cliente.razaoSocial}
                  </p>
                  <p style={{ marginTop: "4px" }} key={`${option.cliente.idCliente}-p2`}>
                    <i key={`${option.cliente.idCliente}-p2i`}>{option.cidade.nomeCidade}</i>
                  </p>
                </div>
              ),
            };
          });

          setClienteOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        buscandoClientes.setLoading(false);
      }
    },
    [Modal, buscandoClientes, customFetch]
  );

  const buscarClientesDebounced = useDebounce((description = "") => {
    buscarClientes(description);
  });

  const buscarPedidosPremiacoes = useCallback(
    async ({ cliente, dataFinal, dataInicial, empresas, estadoPremiacoes, idPedido }: Form) => {
      try {
        buscandoPedidosPremiacoes.setLoading(true);
        const json = (await customFetch("/premiations/finance/buscarPremiacoesParaBaixaManual", {
          body: {
            idPedido: Number(idPedido),
            idCliente: Number(cliente?.value.cliente.idCliente),
            idEmpresas: empresas.map((x) => x.value.idEmpresa),
            status: estadoPremiacoes.value,
            dataInicial: new Date(dataInicial).toISOString(),
            dataFinal: new Date(dataFinal).toISOString(),
          },
        })) as DefaultFetchResponse<Pedido[]>;
        if (json.status === 200) {
          setListaPedidos(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setListaPedidos([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        buscandoPedidosPremiacoes.setLoading(false);
      }
    },
    [Modal, buscandoPedidosPremiacoes, customFetch]
  );

  const resetarDadosSelecionado = useCallback(() => {
    setPedidoSelecionado(null);
  }, []);

  useEffect(() => {
    buscarEmpresas();
    buscarClientes();
  }, []); // eslint-disable-line

  return {
    form,
    listaPedidos,
    pedidosTableData,
    resetarDadosSelecionado,
    pedidoSelecionado: {
      value: pedidoSelecionado,
      setValue: setPedidoSelecionado,
    },
    buscarPedidosPremiacoes: {
      buscar: buscarPedidosPremiacoes,
      loading: buscandoPedidosPremiacoes.isLoading,
    },
    options: {
      empresas: {
        value: empresaOptions,
        loading: buscandoEmpresas.isLoading,
        buscar: buscarEmpresas,
      },
      clientes: {
        value: clienteOptions,
        loading: buscandoClientes.isLoading,
        buscar: buscarClientes,
        buscarDebounced: buscarClientesDebounced,
      },
      estadoPremiacoes: {
        value: estadoPremiacoesOptions,
      },
    },
  };
}

export type UseBaixaManual = ReturnType<typeof useBaixaManual>;
