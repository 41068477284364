import React from "react";
import { Route, Routes } from "react-router-dom";
import Lista from "./Lista/Lista";
import useBaixasManuaisRealizadas from "./hooks/useBaixasManuaisRealizadas";
import Detalhes from "./Detalhes/Detalhes";

export default function BaixasManuaisRealizadas() {
  const data = useBaixasManuaisRealizadas();

  return (
    <Routes>
      <Route path="/" element={<Lista data={data} />} />
      <Route path="detalhes" element={<Detalhes data={data} />} />
    </Routes>
  );
}
