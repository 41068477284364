import React from "react";

import { formatDate, formatMoney } from "../../../../../helpers/format";

import { Button, InputMultiple, Select } from "../../../../../components/Form";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../../components/Data/Table";
import { Paginate } from "../../../../../components/Paginate/Paginate";

import { Eye } from "phosphor-react";
import styles from "./PendingRequestsList.module.css";
import { Circle } from "../../../../../components/Loading";
import { useDebounce } from "../../../../../hooks/async";
import { useNavigate } from "react-router-dom";
import { selectOptionsDifference } from "../../../../../helpers/List";

export function PendingRequestsList({
  scrollPosition,
  filteredManager,
  filterCustomer,
  filteredSolicitation,
  managerOptions,
  customerOptions,
  requestList,
  searchManagers,
  searchCustomers,
  searchTotalRequestRecordsAndRequests,
  searchRequests,
  pagination,
  setSelectedRequest,
}) {
  const navigate = useNavigate();

  const searchManagersDebounced = useDebounce(searchManagers);
  const searchCustomersDebounced = useDebounce(searchCustomers);

  const setCurrentScrollPosition = () => {
    scrollPosition.current = window.scrollY;
  };

  React.useEffect(() => {
    window.scrollTo(0, scrollPosition.current);

    setSelectedRequest(null);
    if (!requestList.value.length) searchTotalRequestRecordsAndRequests();
    if (!managerOptions.value.length) searchManagers();
    if (!customerOptions.value.length) searchCustomers();
  }, []); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.filtersContainer}>
        <div>
          <label htmlFor="filteredSolicitation" className="label">
            ID Solicitação
          </label>
          <InputMultiple
            id="filteredSolicitation"
            placeholder="Digite o id da solicitação"
            value={filteredSolicitation}
          />
        </div>
        <div>
          <label htmlFor="filteredManager" className="label">
            Gerente
          </label>
          <Select
            id="filteredManager"
            placeholder="Selecione um gerente para filtrar"
            value={filteredManager.value}
            onChange={(value) => {
              filteredManager.setValue(value);
            }}
            onInputChange={(value, action) => {
              if (action.action !== "set-value") {
                searchManagersDebounced(value);
              }
            }}
            options={selectOptionsDifference(managerOptions.value, filteredManager.value)}
            isLoading={managerOptions.isSearching}
            isMulti
            closeMenuOnSelect={false}
          />
        </div>
        <div>
          <label htmlFor="filterCustomer" className="label">
            Cliente
          </label>
          <Select
            id="filterCustomer"
            placeholder="Selecione um cliente para filtrar"
            value={filterCustomer.value}
            onChange={(value) => {
              filterCustomer.setValue(value);
            }}
            onInputChange={(value, action) => {
              if (action.action !== "set-value") {
                searchCustomersDebounced(value);
              }
            }}
            options={selectOptionsDifference(customerOptions.value, filterCustomer.value)}
            isLoading={customerOptions.isSearching}
            isMulti
            closeMenuOnSelect={false}
          />
        </div>
        <Button
          className={styles.filtersContainer__btnSearchSolicitation}
          onClick={() => {
            searchTotalRequestRecordsAndRequests();
          }}
        >
          Buscar Solicitações
        </Button>
      </div>
      <span className="separator" />
      <div className={styles.dataContainer}>
        {requestList.value.length && !requestList.isSearching ? (
          <div className={styles.requestsTableContainer}>
            {requestList.value.map((supervisorItem, index) => (
              <div className={styles.requestTableSupervisorContainer} key={index}>
                <h2 className={styles.supervisorTitle}>{supervisorItem.nomeSupervisor}</h2>
                <div className={styles.clientItems}>
                  {supervisorItem.clientes.map((clientItem, index) => {
                    return (
                      <div className={styles.table} key={index}>
                        <Table title={clientItem.nomeCliente} className={styles.requestsTable}>
                          <THead>
                            <Tr>
                              <Th>ID Solicitação</Th>
                              <Th>Gerente Solicitante</Th>
                              <Th>Valor Solicitado</Th>
                              <Th>Data</Th>
                              <Th>Anos</Th>
                              <Th></Th>
                            </Tr>
                          </THead>
                          <TBody>
                            {clientItem.solicitacoes.map((request, index) => {
                              return (
                                <Tr key={index}>
                                  <Td heading="ID Solicitação" width="10rem">
                                    {request.idSolicitacao}
                                  </Td>
                                  <Td heading="Gerente Solicitante">{request.gerenteSolicitante}</Td>
                                  <Td heading="Valor Solicitado" width="180px">
                                    {formatMoney(request.valorSolicitacao)}
                                  </Td>
                                  <Td heading="Data" width="11.25rem">
                                    {formatDate(request.dataSolicitacao, "dd/MM/yy às hh:mm:ss")}
                                  </Td>
                                  <Td heading="Anos" style={{ textAlign: "center" }} width="11.25rem">
                                    {request.anos.sort().join(" - ")}
                                  </Td>
                                  <Td heading="Ver Solicitação" width="6.25rem" data-option>
                                    <Button
                                      type="button"
                                      variant="edit"
                                      className={styles.seeRequestButton}
                                      onClick={() => {
                                        setCurrentScrollPosition();
                                        setSelectedRequest(request);
                                        navigate("premiacoes");
                                      }}
                                      data-option-button
                                    >
                                      <Eye weight="fill" />
                                    </Button>
                                  </Td>
                                </Tr>
                              );
                            })}
                          </TBody>
                        </Table>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        ) : requestList.isSearching ? (
          <div className={`loadingContainer ${styles.loadingRequestListContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma solicitação encontrada</p>
        )}
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => searchRequests(page)}
        />
      </div>
    </div>
  );
}
