import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Button, Input, Select } from "../../../../components/Form";

import styles from "./Lista.module.css";
import { UseBaixasManuaisRealizadas } from "../hooks/useBaixasManuaisRealizadas";
import { Table } from "../../../../components/Data/XTable";
import { Circle } from "../../../../components/Loading";
import { Paginate } from "../../../../components/Paginate/Paginate";

interface Props {
  data: UseBaixasManuaisRealizadas;
}

export default function Lista({ data }: Props) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = data.form;

  const empresas = watch("empresas");

  useEffect(() => {
    data.resetarDados();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.formContainer}>
        <div className={styles.formContainer__idPedido}>
          <Controller
            name="idPedido"
            control={control}
            render={({ field }) => (
              <>
                <label htmlFor="idPedido" className="label">
                  ID Pedido
                </label>
                <Input {...field} id="idPedido" placeholder="Digite o id do pedido" error={errors.idPedido?.message} />
              </>
            )}
          />
        </div>
        <div className={styles.formContainer__empresas}>
          <Controller
            name="empresas"
            control={control}
            rules={{
              required: "Selecione ao menos uma empresa",
            }}
            render={({ field }) => (
              <>
                <label htmlFor="empresas" className="label">
                  Empresas
                </label>
                <Select
                  {...field}
                  value={empresas}
                  id="empresas"
                  options={data.options.empresas.value}
                  placeholder="Selecione a empresa que deseja filtrar"
                  error={errors.empresas?.message}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  isLoading={data.options.empresas.loading}
                />
              </>
            )}
          />
        </div>
        <div className={styles.formContainer__cliente}>
          <Controller
            name="cliente"
            control={control}
            render={({ field }) => (
              <>
                <label htmlFor="cliente" className="label">
                  Cliente
                </label>
                <Select
                  {...field}
                  id="cliente"
                  options={data.options.clientes.value}
                  placeholder="Selecione o cliente que deseja filtrar"
                  onInputChange={data.options.clientes.buscarDebounced}
                  error={errors.cliente?.message}
                  isLoading={data.options.clientes.loading}
                  filterOption={(option: any, inputValue) =>
                    option.data.label.toLowerCase().includes(inputValue.toLowerCase())
                  }
                  getOptionLabel={(option: any) => option.customLabel}
                />
              </>
            )}
          />
        </div>
        <div className={styles.formContainer__dataInicial}>
          <Controller
            name="dataInicial"
            control={control}
            rules={{
              required: "Selecione uma data inicial",
            }}
            render={({ field }) => {
              return (
                <>
                  <label htmlFor="dataInicial" className="label">
                    Data Inicial
                  </label>
                  <Input {...field} id="dataInicial" type="date" error={errors.dataInicial?.message} />
                </>
              );
            }}
          />
        </div>
        <div className={styles.formContainer__dataFinal}>
          <Controller
            name="dataFinal"
            control={control}
            rules={{
              required: "Selecione uma data final",
            }}
            render={({ field }) => {
              return (
                <>
                  <label htmlFor="dataFinal" className="label">
                    Data Final
                  </label>
                  <Input {...field} id="dataFinal" type="date" error={errors.dataFinal?.message} />
                </>
              );
            }}
          />
        </div>
        <Button
          className={styles.formContainer__buttonBuscarPremiacoes}
          onClick={() => {
            handleSubmit((form) => data.buscarBaixasManuais.buscar(form, 0, true))();
          }}
        >
          Buscar Premiações
        </Button>
      </div>
      <span className="separator" />
      {data.buscarBaixasManuais.possuiDados && !data.buscarBaixasManuais.loading ? (
        <Table tableData={data.buscarBaixasManuais.tableData} />
      ) : data.buscarBaixasManuais.loading ? (
        <div className="loadingContainer" style={{ justifyContent: "center", height: "500px" }}>
          <Circle size={100} />
        </div>
      ) : (
        <p className="lineCardMessage">Nenhuma Baixa Encontrada</p>
      )}
      <Paginate
        totalRecords={data.pagination.totalRecords}
        setCurrentPage={data.pagination.setCurrentPage}
        onPageChange={(page) => handleSubmit((form) => data.buscarBaixasManuais.buscar(form, page))()}
      />
    </div>
  );
}
