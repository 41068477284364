import React from "react";

import { Navigate, useNavigate } from "react-router-dom";

import { formatMoney } from "../../../../../helpers/format";
import { isValid } from "../../../../../helpers/validations";
// import { downloadResponseFile } from "../../../../../helpers/responses";

import { useModal } from "../../../../../hooks/contexts";
import { useCustomFetch, useInfiniteScroll, useLoading } from "../../../../../hooks/async";
import { useForm, useSelect } from "../../../../../hooks/form";

import { Input, Button, LinkButton, Textarea, Select } from "../../../../../components/Form";

import { Circle } from "../../../../../components/Loading";
import styles from "./RequestedPremiations.module.css";

const messageColor = (premiation) => {
  if (premiation.permitidoPagar <= 0) {
    return "#bd0000";
  } else if (premiation.permitidoPagar < premiation.valor) {
    return "#d65600";
  } else {
    return "";
  }
};

const everySelected = (premiationList) => {
  let isSelected = true;
  return premiationList.premiacoes.every((clientItem) => {
    clientItem.pedidos.every((orderItem) => {
      orderItem.premiacoes.every((premiationItem) => {
        if (!premiationItem.controll.selected) {
          isSelected = false;
        }
        return isSelected;
      });
      return isSelected;
    });
    return isSelected;
  });
};

const isInvalidDate = (stringDate) => {
  const today = new Date().toJSON().replace(/(\d{4}-\d{2}-\d{2}).+/, "$1");
  return today > stringDate;
};

export function RequestedPremiations({ selectedRequest, searchTotalRequestRecordsAndRequests }) {
  const [premiationList, setPremiationList] = React.useState({});

  const modalReasonRef = React.useRef(null);
  const [loadMoreRef] = useInfiniteScroll(() => {
    if (searchingPremiations.isLoading) return;
    searchPremiations(premiationList, currentPage);
  });

  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = React.useState(0);

  const [currentStep, setCurrentStep] = React.useState(1);

  const reason = useForm({ type: "text", required: true });
  const releasedValue = useForm({ type: "number", required: true });
  const defaultPaymentType = useSelect({ type: "single", required: true });
  const [paymentTypeOptions, setPaymentTypeOptions] = React.useState([]);

  const searchingPremiations = useLoading();
  const searchingPaymentTypeOptions = useLoading();
  const generatingReport = useLoading();
  const processingRequest = useLoading();
  const [noMorePremiations, setNoMorePremiations] = React.useState(false);

  const dataInformations = {
    totalSelectedPremiations: 0,
    totalPaymentValue: 0,
    totalReleased: 0,
    totalTons: 0,
  };
  if (premiationList.premiacoes) {
    let totalSelectedPremiations = 0;
    let totalPaymentValue = 0;
    let totalReleased = 0;
    let totalTons = 0;
    premiationList.premiacoes.forEach((clientItem) => {
      clientItem.pedidos.forEach((orderItem) => {
        orderItem.premiacoes.forEach((premiationItem) => {
          if (premiationItem.controll.selected) {
            totalSelectedPremiations++;
            totalPaymentValue += Number(premiationItem.controll.paymentValue);
            totalReleased += Number(premiationItem.permitidoPagar);
            totalTons += Number(premiationItem.pesoBruto);
          }
        });
      });
    });
    dataInformations.totalSelectedPremiations = totalSelectedPremiations;
    dataInformations.totalPaymentValue = totalPaymentValue;
    dataInformations.totalReleased = totalReleased;
    dataInformations.totalTons = totalTons;
  }

  const searchPremiationsPromisse = React.useCallback(
    async (currentPage) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/premiations/finance/searchRequestedPremiations", {
            body: {
              pagina: currentPage,
              tamanho: 1000,
              idSolicitacao: selectedRequest.idSolicitacao,
              idCliente: selectedRequest.idCliente,
              idSupervisor: selectedRequest.idSupervisor,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, selectedRequest]
  );

  const searchPaymentTypes = React.useCallback(async () => {
    try {
      searchingPaymentTypeOptions.setLoading(true);
      const json = await customFetch("/premiations/manager/searchPaymentTypes", {
        method: "GET",
        headers: { Accept: "application/json" },
      });
      if (json.status === 200) {
        const options = json.object.map((option) => {
          return {
            value: option,
            label: `${option.descricaoPagamento}`,
          };
        });
        setPaymentTypeOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingPaymentTypeOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingPaymentTypeOptions]);

  const formatReturnedPremiations = React.useCallback((object) => {
    const today = new Date().toJSON().replace(/(\d{4}-\d{2}-\d{2}).+/, "$1");

    const newObject = {
      premiacoes: [],
    };
    object.forEach((item) => {
      const client = newObject.premiacoes.find((client) => client.idCliente === item.colaborador.idCliente);
      if (client !== undefined) {
        const order = client.pedidos.find((order) => order.idPedido === item.pedido.idPedido);
        if (order !== undefined) {
          order.premiacoes.push({
            pesoBruto: item.pedido.pesoBruto,
            qtdPedida: 0,
            qtdPerdida: 0,
            qtdEntregueSiagri: 0,
            qtdDevolvidaSiagri: 0,
            qtdPerdidaSiagri: 0,
            permitidoPagar: item.premiacoes.valor,
            motivo: "",
            idPremiacao: item.premiacao.idPremiacao,
            valorOriginal: item.premiacao.valorOriginal,
            descricao: item.premiacao.descricao,
            valorSaldo: item.premiacao.valorSaldo,
            valor: item.premiacoes.valor,
            controll: {
              selected: false,
              paymentValue: 0,
              paymentType: item.formaPagamento.idTipoPagamento,
              paymentDate: today,
            },
          });
        } else {
          client.pedidos.push({
            idPedido: item.pedido.idPedido,
            idPedidoSiagri: item.pedido.idPedidoSiagri,
            premiacoes: [
              {
                pesoBruto: item.pedido.pesoBruto,
                qtdPedida: 0,
                qtdPerdida: 0,
                qtdEntregueSiagri: 0,
                qtdDevolvidaSiagri: 0,
                qtdPerdidaSiagri: 0,
                permitidoPagar: item.premiacoes.valor,
                motivo: "",
                idPremiacao: item.premiacao.idPremiacao,
                valorOriginal: item.premiacao.valorOriginal,
                descricao: item.premiacao.descricao,
                valorSaldo: item.premiacao.valorSaldo,
                valor: item.premiacoes.valor,
                controll: {
                  selected: false,
                  paymentValue: 0,
                  paymentType: item.formaPagamento.idTipoPagamento,
                  paymentDate: today,
                },
              },
            ],
          });
        }
      } else {
        newObject.premiacoes.push({
          idCliente: item.colaborador.idCliente,
          razaoSocial: item.colaborador.razaoSocial,
          pedidos: [
            {
              idPedido: item.pedido.idPedido,
              idPedidoSiagri: item.pedido.idPedidoSiagri,
              premiacoes: [
                {
                  pesoBruto: item.pedido.pesoBruto,
                  qtdPedida: 0,
                  qtdPerdida: 0,
                  qtdEntregueSiagri: 0,
                  qtdDevolvidaSiagri: 0,
                  qtdPerdidaSiagri: 0,
                  permitidoPagar: item.premiacoes.valor,
                  motivo: "",
                  idPremiacao: item.premiacao.idPremiacao,
                  valorOriginal: item.premiacao.valorOriginal,
                  descricao: item.premiacao.descricao,
                  valorSaldo: item.premiacao.valorSaldo,
                  valor: item.premiacoes.valor,
                  controll: {
                    selected: false,
                    paymentValue: 0,
                    paymentType: item.formaPagamento.idTipoPagamento,
                    paymentDate: today,
                  },
                },
              ],
            },
          ],
        });
      }
    });

    return newObject;
  }, []);

  const searchPremiations = React.useCallback(
    async (premiationList, currentPage) => {
      try {
        searchingPremiations.setLoading(true);
        const json = await searchPremiationsPromisse(currentPage);
        if (json.status === 200) {
          const adjustedJson = formatReturnedPremiations(json.object);

          let currentPremiations = { ...adjustedJson };
          if (currentPage > 0) {
            currentPremiations = { ...premiationList };
            currentPremiations.premiacoes.forEach((currentPremiationsClient) => {
              adjustedJson.premiacoes.every((jsonPremiationsClient) => {
                if (currentPremiationsClient.idCliente === jsonPremiationsClient.idCliente) {
                  currentPremiationsClient.pedidos.push(...jsonPremiationsClient.pedidos);
                  return false;
                }
                return true;
              });
            });
            adjustedJson.premiacoes.forEach((adjustedItem) => {
              if (
                !currentPremiations.premiacoes.some((currentItem) => currentItem.idCliente === adjustedItem.idCliente)
              ) {
                currentPremiations.premiacoes.push({ ...adjustedItem });
              }
            });
          }
          // currentPremiations.premiacoes = currentPremiations.premiacoes.filter(
          //   (clientItem) => clientItem.idCliente === selectedRequest.colaborador.idCliente
          // );
          setPremiationList(currentPremiations);
          setCurrentPage(currentPage + 1);
          setNoMorePremiations(false);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setNoMorePremiations(true);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPremiations.setLoading(false);
      }
    },
    [searchingPremiations, searchPremiationsPromisse, formatReturnedPremiations, Modal]
  );

  const gotoStepOne = React.useCallback(() => {
    setCurrentStep(1);

    const listContainer = document.querySelector("#dataFormContainer__listDiv");
    listContainer.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const gotoStepTwo = React.useCallback(() => {
    let haveSelectedItems = false;
    premiationList.premiacoes.every((clientItem) => {
      clientItem.pedidos.every((orderItem) => {
        orderItem.premiacoes.every((premiationItem) => {
          if (premiationItem.controll.selected) {
            haveSelectedItems = true;
          }
          return !haveSelectedItems;
        });
        return !haveSelectedItems;
      });
      return !haveSelectedItems;
    });
    if (haveSelectedItems) {
      let haveInvalidValue = false;
      let invalidMessage = "";
      premiationList.premiacoes.every((clientItem) => {
        clientItem.pedidos.every((orderItem) => {
          orderItem.premiacoes.every((premiationItem) => {
            if (premiationItem.controll.selected) {
              if (premiationItem.controll.paymentValue > premiationItem.valor) {
                haveInvalidValue = true;
                invalidMessage = "Não é possível prosseguir com valores acima do liberado!";
              } else if (premiationItem.controll.paymentValue <= 0) {
                haveInvalidValue = true;
                invalidMessage = "Não é possível prosseguir com valores zerados!";
              } else if (isInvalidDate(premiationItem.controll.paymentDate)) {
                haveInvalidValue = true;
                invalidMessage = "Não é possível prosseguir com datas de pagamento inválidas!";
              }
            }
            return !haveInvalidValue;
          });
          return !haveInvalidValue;
        });
        return !haveInvalidValue;
      });
      if (!haveInvalidValue) {
        setCurrentStep(2);
        const listContainer = document.querySelector("#dataFormContainer__listDiv");
        listContainer.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        Modal.error(invalidMessage);
      }
    } else {
      Modal.error("É necessário selecionar ao menos um item para prosseguir.");
    }
  }, [Modal, premiationList]);

  const handleChangeCheckSelectAll = React.useCallback(
    ({ target }) => {
      const active = !!target.checked;

      premiationList.premiacoes.forEach((clientItem) => {
        clientItem.pedidos.forEach((orderItem) => {
          orderItem.premiacoes.forEach((premiationItem) => {
            if (active) {
              premiationItem.controll.paymentValue = premiationItem.valor;
            } else {
              premiationItem.controll.paymentValue = 0;
            }
            premiationItem.controll.selected = active;
          });
        });
      });

      const alteredObject = { ...premiationList };
      setPremiationList(alteredObject);
    },
    [premiationList]
  );

  const handleClickSelectMaxValue = React.useCallback(() => {
    if (isValid(releasedValue)) {
      let maxValue = Number(releasedValue.value);
      const object = { ...premiationList };
      object.premiacoes.forEach((clientItem) => {
        clientItem.pedidos.forEach((orderItem) => {
          orderItem.premiacoes.forEach((premiationItem) => {
            if (maxValue > 0) {
              if (maxValue >= Number(premiationItem.valor)) {
                premiationItem.controll.selected = true;
                premiationItem.controll.paymentValue = premiationItem.valor;
                maxValue -= Number(premiationItem.valor);
              } else {
                premiationItem.controll.selected = true;
                premiationItem.controll.paymentValue = maxValue;
                maxValue = 0;
              }
            } else {
              premiationItem.controll.selected = false;
            }
          });
        });
      });

      setPremiationList(object);
      releasedValue.reset();
    }
  }, [premiationList, releasedValue]);

  const handleChangeDefaultPaymentType = async (value) => {
    const confirm = await Modal.confirm(
      "<p>Esta ação irá mudar o método de pagamento de todos os itens.</p><p>Deseja continuar?</p>"
    );
    if (confirm) {
      defaultPaymentType.setValue(value);
      const list = { ...premiationList };
      list.premiacoes.forEach((clientItem) => {
        clientItem.pedidos.forEach((orderItem) => {
          orderItem.premiacoes.forEach((premiationItem) => {
            premiationItem.controll.paymentType = value.value.idTipoPagamento;
          });
        });
      });
      setPremiationList(list);
    }
  };

  const handleChangeCheckPremiation = (e, premiationItem) => {
    if (e.target.checked) {
      premiationItem.controll.paymentValue = premiationItem.valor;
      premiationItem.controll.selected = true;
    } else {
      premiationItem.controll.paymentValue = 0;
      premiationItem.controll.selected = false;
    }
    const changedList = { ...premiationList };

    setPremiationList(changedList);
  };

  const handleChangePaymentValue = (e, premiationItem) => {
    premiationItem.controll.paymentValue = e.target.value;
    const changedList = { ...premiationList };
    setPremiationList(changedList);
  };

  const handleChangePaymentType = (e, premiationItem) => {
    premiationItem.controll.paymentType = e.target.value;
    const changedList = { ...premiationList };
    setPremiationList(changedList);
  };

  const handleChangePaymentDate = (e, premiationItem) => {
    premiationItem.controll.paymentDate = e.target.value;
    const changedList = { ...premiationList };
    setPremiationList(changedList);
  };

  // const handleClickGenerateReport = React.useCallback(
  //   async (fileType) => {
  //     const url =
  //       fileType === "excel"
  //         ? "/premiations/report/generatePremiationsReport/excel"
  //         : "/premiations/report/generatePremiationsReport/pdf";

  //     const premiationsArray = [];
  //     premiationList.premiacoes.forEach((clientItem) => {
  //       clientItem.pedidos.forEach((orderItem) => {
  //         orderItem.premiacoes.forEach((premiationItem) => {
  //           if (premiationItem.controll.selected) {
  //             premiationsArray.push(Number(premiationItem.idPremiacao));
  //           }
  //         });
  //       });
  //     });

  //     try {
  //       generatingReport.setLoading(true);
  //       const response = await customFetch(url, {
  //         body: {
  //           pagina: 0,
  //           tamanho: 1000,
  //           idsPremiacao: premiationsArray,
  //         },
  //       });
  //       if (response.status === 200) {
  //         downloadResponseFile({
  //           file: response.object,
  //           type: "application/pdf",
  //           fileName: "relatorio-premiacoes-selecionadas.pdf",
  //         });
  //       } else if (response.status === 500) {
  //         Modal.error(response.message, response.object);
  //       }
  //     } catch (error) {
  //       Modal.error(error);
  //     } finally {
  //       generatingReport.setLoading(false);
  //     }
  //   },
  //   [Modal, customFetch, premiationList, generatingReport]
  // );

  const handleClickApproveRequest = React.useCallback(
    async (premiationList) => {
      const sendedArray = [];
      premiationList.premiacoes.forEach((clientItem) => {
        clientItem.pedidos.forEach((orderItem) => {
          orderItem.premiacoes.forEach((premiationItem) => {
            if (premiationItem.controll.selected) {
              sendedArray.push({
                idSolicitacao: Number(selectedRequest.idSolicitacao),
                idPremiacao: Number(premiationItem.idPremiacao),
                idFormaPagamento: Number(premiationItem.controll.paymentType),
                valorPagar: Number(premiationItem.controll.paymentValue),
                dataPagamento: premiationItem.controll.paymentDate,
              });
            }
          });
        });
      });

      const confirm = await Modal.confirm("Deseja mesmo concluir o pagamento?");

      if (confirm) {
        try {
          processingRequest.setLoading(true);
          const json = await customFetch("/premiations/finance/approveRequestedPremiations", {
            body: sendedArray,
          });
          if (json.status === 200) {
            let message = `<p>${json.object.message}</p>`;

            if (json.object.erroList) {
              var errorList = (() => {
                let string = "";
                json.object.erroList.forEach((error) => {
                  string += `<p>${error}</p><br>`;
                });
                return string
                  ? `<div style="margin-top: 10px;">
                        <span style="margin: auto; margin-bottom: 10px; display: block; height: 1px; background-color: var(--gray-8); width: 60%; border-radius: 100%;"></span>
                        ${string}
                      </div>`
                  : "";
              })();

              message += errorList;
            }
            await Modal.success(message);
            searchTotalRequestRecordsAndRequests();
            navigate("/premiacoes/financeiro/em-analise-financeiro");
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          processingRequest.setLoading(false);
        }
      }
    },
    [Modal, customFetch, navigate, processingRequest, searchTotalRequestRecordsAndRequests, selectedRequest]
  );

  const handleClickReproveRequest = React.useCallback(
    async (premiationList, reason = "") => {
      const sendedArray = [];
      premiationList.premiacoes.forEach((clientItem) => {
        clientItem.pedidos.forEach((orderItem) => {
          orderItem.premiacoes.forEach((premiationItem) => {
            if (premiationItem.controll.selected) {
              sendedArray.push({
                idSolicitacao: Number(selectedRequest.idSolicitacao),
                idPremiacao: Number(premiationItem.idPremiacao),
                idFormaPagamento: Number(premiationItem.controll.paymentType),
                valorPagar: Number(premiationItem.controll.paymentValue),
                motivo: reason,
                dataPagamento: premiationItem.controll.paymentDate,
              });
            }
          });
        });
      });
      try {
        processingRequest.setLoading(true);
        const json = await customFetch("/premiations/finance/reproveRequestedPremiations", {
          body: sendedArray,
        });
        if (json.status === 200) {
          await Modal.success(json.message);
          searchTotalRequestRecordsAndRequests();
          navigate("/premiacoes/financeiro/em-analise-financeiro");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        processingRequest.setLoading(false);
      }
    },
    [Modal, customFetch, navigate, processingRequest, searchTotalRequestRecordsAndRequests, selectedRequest]
  );

  // export default function countBy(arr: (string | number)[]) {
  //   return arr.reduce((acc: CountList, payment) => {
  //     if (acc[payment] !== undefined) {
  //       acc[payment] += 1;
  //     } else {
  //       acc[payment] = 1;
  //     }
  //     return acc;
  //   }, {});
  // }

  React.useEffect(() => {
    if (selectedRequest !== null) {
      searchPaymentTypes();
      searchPremiations({}, 0);
    }
  }, []); //eslint-disable-line

  if (!selectedRequest) {
    return <Navigate to="/premiacoes/financeiro/em-analise-financeiro" />;
  }

  return (
    <>
      <div className="container">
        <div className={styles.navigationContainer}>
          <div>
            <LinkButton to="/premiacoes/financeiro/em-analise-financeiro" buttonStyle="backButton" />
          </div>
          <h2 className={styles.navigationContainer__header}>
            {selectedRequest.idSolicitacao} - {selectedRequest.gerenteSolicitante}
          </h2>
          <div></div>
        </div>
        <div className={styles.contentContainer}>
          <form className={`${styles.dataFormContainer}`}>
            {premiationList.premiacoes && !processingRequest.isLoading && !generatingReport.isLoading ? (
              <>
                <div className={styles.stepsContainer}>
                  <button
                    type="button"
                    className={`${currentStep === 1 || currentStep === 2 ? "active" : ""}`}
                    onClick={gotoStepOne}
                  >
                    <p>1</p>
                    <p>Seleção dos Itens</p>
                  </button>
                  <button type="button" className={`${currentStep === 2 ? "active" : ""}`} onClick={gotoStepTwo}>
                    <p>2</p>
                    <p>Conferência</p>
                  </button>
                </div>
                <div className={`${styles.dataFormContainer__main}`}>
                  <div
                    id="dataFormContainer__listDiv"
                    className={`${styles.dataFormContainer__listDiv} customScrollbar`}
                  >
                    <ul className={styles.dataForm__clientList}>
                      {currentStep === 1 ? (
                        <>
                          <div className={styles.manipulatePremiationsContainer}>
                            <div className={styles.selectMaxValueContainer}>
                              <div>
                                <label htmlFor="releasedValue" className="label">
                                  Valor Liberado
                                </label>
                                <Input
                                  className="cleanInputNumber"
                                  id="releasedValue"
                                  type="number"
                                  placeholder="Digite o valor máximo que deseja pagar"
                                  value={releasedValue.value}
                                  error={releasedValue.error}
                                  onChange={releasedValue.onChange}
                                />
                              </div>
                              <Button
                                type="button"
                                className={styles.selectMaxValueButton}
                                onClick={() => {
                                  handleClickSelectMaxValue();
                                }}
                              >
                                Selecionar
                              </Button>
                            </div>
                            <div className={styles.defaultPaymentTypeContainer}>
                              <label htmlFor="defaultPaymentType" className="label">
                                Método de Pagamento Padrão
                              </label>
                              <Select
                                id="defaultPaymentType"
                                placeholder="Selecione um método de pagamento padrão"
                                options={paymentTypeOptions}
                                value={defaultPaymentType.value}
                                onChange={handleChangeDefaultPaymentType}
                                isClearable={false}
                              />
                            </div>
                            <div className={styles.selectAllContainer}>
                              <label className="label">
                                <input
                                  type="checkbox"
                                  onChange={handleChangeCheckSelectAll}
                                  checked={everySelected(premiationList)}
                                />
                                Selecionar todos
                              </label>
                            </div>
                          </div>
                          {premiationList.premiacoes.map((clientItem, index) => {
                            return (
                              <li key={index}>
                                <header>
                                  <p>
                                    {clientItem.idCliente} - {clientItem.razaoSocial}
                                  </p>
                                </header>
                                <ul className={styles.dataForm__clientList__orderList}>
                                  {clientItem.pedidos.map((orderItem, index) => {
                                    return (
                                      <li key={index}>
                                        <header>
                                          <p>
                                            Pedido - {orderItem.idPedido} / {orderItem.idPedidoSiagri}
                                          </p>
                                        </header>
                                        <ul className={styles.dataForm__clientList__orderList__premiationList}>
                                          {orderItem.premiacoes.map((premiationItem, index) => {
                                            return (
                                              <li
                                                key={index}
                                                style={{ opacity: premiationItem.permitidoPagar <= 0 ? "0.4" : "" }}
                                              >
                                                <div>
                                                  <label
                                                    className={`${styles.premiatedCheckLabelContainer} ${
                                                      premiationItem.permitidoPagar <= 0 ? "disabled" : ""
                                                    }`}
                                                  >
                                                    <div>
                                                      <input
                                                        className={styles.premiatedCheckInput}
                                                        type="checkbox"
                                                        onChange={(event) => {
                                                          handleChangeCheckPremiation(event, premiationItem);
                                                        }}
                                                        checked={premiationItem.controll.selected}
                                                        disabled={premiationItem.permitidoPagar <= 0}
                                                      />
                                                      <span />
                                                    </div>
                                                    <div>
                                                      <p
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          gap: "10px",
                                                          textTransform: "capitalize",
                                                        }}
                                                      >
                                                        <span>{premiationItem.descricao}</span>
                                                        <span
                                                          style={{
                                                            display: "block",
                                                            height: "2px",
                                                            width: "30px",
                                                            backgroundColor: "var(--gray-8)",
                                                            borderRadius: "2px",
                                                          }}
                                                        />
                                                        <span>{formatMoney(premiationItem.valor)}</span>
                                                      </p>
                                                      {premiationItem.motivo && (
                                                        <p
                                                          style={{
                                                            color: messageColor(premiationItem),
                                                            marginTop: "5px",
                                                          }}
                                                        >
                                                          {premiationItem.motivo}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </label>
                                                </div>
                                                <div>
                                                  <label className={`label ${styles.paymentValueLabel}`}>
                                                    <span>Pagar:</span>
                                                    <input
                                                      className={`cleanInputNumber ${
                                                        premiationItem.controll.selected &&
                                                        (premiationItem.controll.paymentValue > premiationItem.valor ||
                                                          premiationItem.controll.paymentValue <= 0)
                                                          ? "isInvalid"
                                                          : ""
                                                      }`}
                                                      type="number"
                                                      value={premiationItem.controll.paymentValue}
                                                      onWheel={(event) => {
                                                        event.target.blur();
                                                      }}
                                                      onKeyDown={(event) => {
                                                        const keys = ["e", "+", "-", "ArrowUp", "ArrowDown"];
                                                        if (keys.includes(event.key)) {
                                                          event.preventDefault();
                                                        }
                                                      }}
                                                      onChange={(event) => {
                                                        handleChangePaymentValue(event, premiationItem);
                                                      }}
                                                      disabled={currentStep === 2}
                                                    />
                                                  </label>
                                                  <label className={`label ${styles.paymentTypeLabel}`}>
                                                    <span>Método:</span>
                                                    <select
                                                      value={premiationItem.controll.paymentType}
                                                      onChange={(event) => {
                                                        handleChangePaymentType(event, premiationItem);
                                                      }}
                                                      disabled={currentStep === 2}
                                                    >
                                                      {paymentTypeOptions.map((option, index) => {
                                                        return (
                                                          <option key={index} value={option.value.idTipoPagamento}>
                                                            {option.label}
                                                          </option>
                                                        );
                                                      })}
                                                    </select>
                                                  </label>
                                                  <label className={`label ${styles.paymentDateLabel}`}>
                                                    <span>Data:</span>
                                                    <input
                                                      type="date"
                                                      className={`${
                                                        premiationItem.controll.selected &&
                                                        isInvalidDate(premiationItem.controll.paymentDate)
                                                          ? "isInvalid"
                                                          : ""
                                                      }`}
                                                      value={premiationItem.controll.paymentDate}
                                                      onChange={(event) => {
                                                        handleChangePaymentDate(event, premiationItem);
                                                      }}
                                                      disabled={currentStep === 2}
                                                    />
                                                  </label>
                                                </div>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            );
                          })}
                        </>
                      ) : (
                        premiationList.premiacoes
                          .filter((clientItem) => {
                            return clientItem.pedidos.some((orderItem) =>
                              orderItem.premiacoes.some((premiationItem) => premiationItem.controll.selected)
                            );
                          })
                          .map((clientItem, index) => {
                            return (
                              <li key={index}>
                                <header>
                                  <p>
                                    {clientItem.idCliente} - {clientItem.razaoSocial}
                                  </p>
                                </header>
                                <ul className={styles.dataForm__clientList__orderList}>
                                  {clientItem.pedidos
                                    .filter((orderItem) => {
                                      return orderItem.premiacoes.some(
                                        (premiationItem) => premiationItem.controll.selected
                                      );
                                    })
                                    .map((orderItem, index) => {
                                      return (
                                        <li key={index}>
                                          <header>
                                            <p>
                                              Pedido - {orderItem.idPedido} / {orderItem.idPedidoSiagri}
                                            </p>
                                          </header>
                                          <ul className={styles.dataForm__clientList__orderList__premiationList}>
                                            {orderItem.premiacoes
                                              .filter((premiationItem) => {
                                                return premiationItem.controll.selected;
                                              })
                                              .map((premiationItem, index) => {
                                                return (
                                                  <li
                                                    key={index}
                                                    style={{
                                                      opacity: premiationItem.permitidoPagar <= 0 ? "0.4" : "",
                                                    }}
                                                  >
                                                    <div>
                                                      <label
                                                        className={`${styles.premiatedCheckLabelContainer} ${
                                                          premiationItem.permitidoPagar <= 0 ? "disabled" : ""
                                                        }`}
                                                      >
                                                        <div>
                                                          <p
                                                            style={{
                                                              display: "flex",
                                                              alignItems: "center",
                                                              gap: "10px",
                                                              textTransform: "capitalize",
                                                            }}
                                                          >
                                                            <span>{premiationItem.descricao}</span>
                                                            <span
                                                              style={{
                                                                display: "block",
                                                                height: "2px",
                                                                width: "30px",
                                                                backgroundColor: "var(--gray-8)",
                                                                borderRadius: "2px",
                                                              }}
                                                            />
                                                            <span>{formatMoney(premiationItem.valor)}</span>
                                                          </p>
                                                          {premiationItem.motivo && (
                                                            <p
                                                              style={{
                                                                color: messageColor(premiationItem),
                                                                marginTop: "5px",
                                                              }}
                                                            >
                                                              {premiationItem.motivo}
                                                            </p>
                                                          )}
                                                        </div>
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <label className={`label ${styles.paymentValueLabel}`}>
                                                        <span>Pagar:</span>
                                                        <input
                                                          className={`cleanInputNumber ${
                                                            premiationItem.controll.selected &&
                                                            (premiationItem.controll.paymentValue >
                                                              premiationItem.valor ||
                                                              premiationItem.controll.paymentValue <= 0)
                                                              ? "isInvalid"
                                                              : ""
                                                          }`}
                                                          type="number"
                                                          value={premiationItem.controll.paymentValue}
                                                          onWheel={(event) => {
                                                            event.target.blur();
                                                          }}
                                                          onKeyDown={(event) => {
                                                            const keys = ["e", "+", "-", "ArrowUp", "ArrowDown"];
                                                            if (keys.includes(event.key)) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          onChange={(event) => {
                                                            handleChangePaymentValue(event, premiationItem);
                                                          }}
                                                          disabled={currentStep === 2}
                                                        />
                                                      </label>
                                                      <label className={`label ${styles.paymentTypeLabel}`}>
                                                        <span>Método:</span>
                                                        <select
                                                          value={premiationItem.controll.paymentType}
                                                          onChange={(event) => {
                                                            handleChangePaymentType(event, premiationItem);
                                                          }}
                                                          disabled={currentStep === 2}
                                                        >
                                                          {paymentTypeOptions.map((option, index) => {
                                                            return (
                                                              <option key={index} value={option.value.idTipoPagamento}>
                                                                {option.label}
                                                              </option>
                                                            );
                                                          })}
                                                        </select>
                                                      </label>
                                                      <label className={`label ${styles.paymentDateLabel}`}>
                                                        <span>Data:</span>
                                                        <input
                                                          type="date"
                                                          className={`${
                                                            isInvalidDate(premiationItem.controll.paymentDate) &&
                                                            "isInvalid"
                                                          }`}
                                                          value={premiationItem.controll.paymentDate}
                                                          onChange={(event) => {
                                                            handleChangePaymentDate(event, premiationItem);
                                                          }}
                                                          disabled={currentStep === 2}
                                                        />
                                                      </label>
                                                    </div>
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </li>
                            );
                          })
                      )}
                    </ul>
                    {currentStep === 1 && !noMorePremiations && (
                      <div className={`loadingContainer ${styles.searchingMorePremiationsLoadingContainer}`}>
                        <p className="loadingMessage" ref={loadMoreRef}>
                          Buscando mais itens
                        </p>
                        <Circle size={30} />
                      </div>
                    )}
                  </div>
                  <div className={styles.dataFormContainer__infoDiv}>
                    <header>
                      <div>
                        <div className={styles.selectedInfo}>
                          <p>
                            <span className="label">Itens Selecionados </span>{" "}
                            {dataInformations.totalSelectedPremiations}
                          </p>
                        </div>
                        <div className={styles.selectedInfo}>
                          <p>
                            <span className="label">Total TN </span>{" "}
                            {Math.round(dataInformations.totalTons * 100) / 100} Tn
                          </p>
                        </div>
                        <div className={styles.selectedInfo}>
                          <p>
                            <span className="label">Total</span> {formatMoney(dataInformations.totalPaymentValue)}
                          </p>
                        </div>
                      </div>
                    </header>
                    <footer>
                      {currentStep === 1 ? (
                        <div>
                          <span></span>
                          <Button type="button" onClick={gotoStepTwo}>
                            Próximo
                          </Button>
                        </div>
                      ) : (
                        <>
                          {/* <div className={styles.generateReportContainer}>
                            <Button
                              type="button"
                              variant="neutral"
                              onClick={() => {
                                handleClickGenerateReport("pdf");
                              }}
                            >
                              Gerar PDF
                            </Button>
                            <Button type="button" variant="neutral" disabled={true}>
                              Gerar Excel
                            </Button>
                          </div> */}
                          <div className={styles.lastOptionsContainer}>
                            <Button type="button" variant="danger" onClick={gotoStepOne}>
                              Voltar
                            </Button>
                            <span
                              style={{
                                height: "2px",
                                backgroundColor: "var(--gray-8)",
                                margin: "10px 0",
                                borderRadius: "100%",
                              }}
                            ></span>
                            <div>
                              <Button
                                type="button"
                                variant="danger"
                                onClick={() => {
                                  modalReasonRef.current.show();
                                }}
                              >
                                Reprovar
                              </Button>
                              <Button type="button" onClick={() => handleClickApproveRequest(premiationList)}>
                                Aprovar
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </footer>
                  </div>
                </div>
              </>
            ) : processingRequest.isLoading ? (
              <div className={`loadingContainer`}>
                <Circle size={100} />
                <span className="loadingMessage">Solicitando Pagamento</span>
              </div>
            ) : generatingReport.isLoading ? (
              <div className={`loadingContainer`}>
                <Circle size={100} />
                <span className="loadingMessage">Gerando Relatório</span>
              </div>
            ) : searchingPremiations.isLoading ? (
              <div className={`loadingContainer`}>
                <Circle size={100} />
              </div>
            ) : (
              <p className={`lineCardMessage`}>Nenhuma premiação encontrada</p>
            )}
          </form>
        </div>
      </div>
      <dialog
        className={`modal ${styles.modalReason}`}
        ref={modalReasonRef}
        onClose={() => {
          reason.reset();
        }}
      >
        <div className="modalContent">
          <div>
            <label htmlFor="reason" className="label">
              Motivo da Reprovação
            </label>
            <Textarea
              id="reason"
              className={styles.textareaReason}
              value={reason.value}
              error={reason.error}
              onChange={reason.onChange}
            ></Textarea>
          </div>
          <span className={styles.modalReason__separator} />
          <div className={styles.modalReason__buttonContainer}>
            <Button
              type="button"
              variant="danger"
              onClick={() => {
                modalReasonRef.current.close();
              }}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              onClick={() => {
                if (isValid(reason)) {
                  modalReasonRef.current.close();
                  handleClickReproveRequest(premiationList, reason.value);
                }
              }}
            >
              Concluir
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
}
