import React, { useEffect } from "react";
import { Table } from "../../../../../components/Data/XTable";
import { LinkButton } from "../../../../../components/Form";
import { Circle } from "../../../../../components/Loading";
import { UseTipos } from "../hooks/useTipos";

import styles from "./Lista.module.css";

interface Props {
  data: UseTipos;
}

export default function Lista({ data }: Props) {
  useEffect(() => {
    data.resetForm();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <nav className={styles.navContainer}>
        <LinkButton to={"novo"}>Novo Tipo</LinkButton>
      </nav>
      <span className="separator" />
      <div>
        {data.tableTipos.data.length > 0 && !data.buscarTipos.loading ? (
          <Table tableData={data.tableTipos} />
        ) : data.buscarTipos.loading ? (
          <div
            className="loadingContainer"
            style={{ height: "500px", justifyContent: "center" }}
          >
            <Circle size={100} />
          </div>
        ) : (
          <p className="lineCardMessage">Nenhum Tipo Encontrado</p>
        )}
      </div>
    </div>
  );
}
