import { useCallback, useEffect, useMemo, useRef } from "react";
import { useModal } from "../../../../../hooks/contexts";
import { useForm } from "react-hook-form";
import { Micro, useSearchValidityTableMicros } from "../../../../../hooks/api/products/useSearchValidityTableMicros";
import {
  Micro as SelectMicro,
  useSearchMicrosPaginated,
} from "../../../../../hooks/api/products/useSearchMicrosPaginated";
import { useUpdateValidityTableMicro } from "../../../../../hooks/api/products/useUpdateValidityTableMicro";

type FormEditOrAddMicro = {
  microEditado: Micro | null;
  novoMicro: ISelectOption<SelectMicro> | null;
  precoMicro: number;
  precoCustoMicro: number;
};

interface Props {
  selectedTable: any;
}

export default function useMicros({ selectedTable }: Props) {
  const Modal = useModal();

  const modalUpdateMicroRef = useRef<HTMLDialogElement>(null);

  const formEditMicro = useForm<FormEditOrAddMicro>({
    defaultValues: {
      novoMicro: null,
      microEditado: null,
    },
    resetOptions: {
      keepValues: false,
    },
  });

  const validityTableMicrosQuery = useSearchValidityTableMicros({
    onError(error) {
      Modal.error(error.response?.data.message, error.response?.data.object);
    },
  });

  const microOptionsQuery = useSearchMicrosPaginated({
    onError(error) {
      Modal.error(error.response?.data.message, error.response?.data.object);
    },
  });

  const microOptions = useMemo<ISelectOption<SelectMicro>[]>(() => {
    return microOptionsQuery.data
      .filter((micro) => !validityTableMicrosQuery.data.find((m) => m.micro.idComplementar === micro.idComplementar))
      .map((option) => ({
        value: option,
        label: `${option.idComplementar} | ${option.descricaoComplementar}`,
      }));
  }, [microOptionsQuery.data, validityTableMicrosQuery.data]);

  const saveMicroQuery = useUpdateValidityTableMicro({
    onSuccess: (data) => {
      modalUpdateMicroRef.current?.close();
      Modal.success(data.data.message);
      searchValidityTableMicros();
    },
    onError: (error) => {
      Modal.error(error.response?.data.message, error.response?.data.object);
    },
  });

  const searchValidityTableMicros = useCallback(() => {
    validityTableMicrosQuery.mutate({ pagina: 0, tamanho: 0, idTabela: selectedTable.value.tabela.id, idMicro: [] });
  }, [selectedTable.value.tabela.id, validityTableMicrosQuery]);

  const searchMicroOptions = useCallback(
    (description: string = "") => {
      microOptionsQuery.mutate({ pagina: 0, tamanho: 1000, descricao: description, status: "A" });
    },
    [microOptionsQuery]
  );

  const saveMicro = useCallback(
    (form: FormEditOrAddMicro) => {
      saveMicroQuery.mutate({
        idTabela: selectedTable.value.tabela.id,
        idMicro: form.microEditado ? form.microEditado.micro.idComplementar : form.novoMicro!.value.idComplementar,
        precoMicro: Number(form.precoMicro),
        precoCustoMicro: Number(form.precoCustoMicro),
      });
    },
    [saveMicroQuery, selectedTable.value.tabela.id]
  );

  const openModal = useCallback(() => {
    modalUpdateMicroRef.current?.show();
    searchMicroOptions();
  }, [searchMicroOptions]);

  const selectMicroToEdit = useCallback(
    (microItem: Micro) => {
      formEditMicro.setValue("microEditado", microItem);
      formEditMicro.setValue("precoMicro", microItem.precoMicro);
      formEditMicro.setValue("precoCustoMicro", microItem.precoCustoMicro);

      openModal();
    },
    [formEditMicro, openModal]
  );

  useEffect(() => {
    searchValidityTableMicros();
  }, []); // eslint-disable-line

  return {
    modalUpdateMicroRef,
    openModal,
    formEditMicro,
    selectMicroToEdit,
    validityTableMicrosQuery: {
      ...validityTableMicrosQuery,
      search: searchValidityTableMicros,
    },
    microOptionsQuery: {
      ...microOptionsQuery,
      data: microOptions,
      search: searchMicroOptions,
    },
    saveMicroQuery: {
      ...saveMicroQuery,
      save: saveMicro,
    },
  };
}

export type UseMicros = ReturnType<typeof useMicros>;
