import React from "react";

import { Select } from "../../../../../components/Form";
import { UseTabletSettings } from "../../hooks/useTabletSettings";
import { Controller } from "react-hook-form";

import styles from "./FatSemDef.module.css";
import { Circle } from "../../../../../components/Loading";

interface Props {
  tabletSettings: UseTabletSettings;
}

export default function FatSemDef({ tabletSettings }: Props) {
  const {
    changes,
    forms: {
      sementeDefensivoForm: {
        control,
        formState: { errors },
      },
    },
    queries: {
      userTabletSettingsQuery,
      supervisorsQuery,
      operationsQuery,
      depositsQuery,
      saveUserSementeDefensivoTabletSettings,
    },
  } = tabletSettings;

  return (
    <div className={styles.formContainer}>
      {!saveUserSementeDefensivoTabletSettings.isFetching ? (
        <>
          <div>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <label htmlFor="active" className="label">
                  <input
                    {...field}
                    id="active"
                    value={undefined}
                    checked={field.value}
                    type="checkbox"
                    onChange={(e) => {
                      field.onChange(e);
                      changes.set();
                    }}
                    disabled={userTabletSettingsQuery.isLoading}
                  />{" "}
                  Ativo
                </label>
              )}
            />
          </div>
          <div>
            <Controller
              name="defaultSeller"
              control={control}
              rules={{ required: "Selecione um vendedor padrão" }}
              render={({ field }) => (
                <>
                  <label htmlFor="defaultSeller" className="label">
                    Vendedor
                  </label>
                  <Select
                    {...field}
                    id="defaultSeller"
                    placeholder="Selecione o vendedor padrão"
                    onChange={(value) => {
                      field.onChange(value);
                      changes.set();
                    }}
                    error={errors.defaultSeller?.message}
                    options={userTabletSettingsQuery.data?.configuracaoTabletVendedor ?? []}
                    isLoading={userTabletSettingsQuery.isLoading}
                    isDisabled={userTabletSettingsQuery.isLoading}
                  />
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="defaultSupervisor"
              control={control}
              rules={{ required: "Selecione um supervisor padrão" }}
              render={({ field }) => (
                <>
                  <label htmlFor="defaultSupervisor" className="label">
                    Supervisor
                  </label>
                  <Select
                    {...field}
                    id="defaultSupervisor"
                    placeholder="Selecione o supervisor padrão"
                    onChange={(value) => {
                      field.onChange(value);
                      changes.set();
                    }}
                    error={errors.defaultSupervisor?.message}
                    options={supervisorsQuery.data}
                    isLoading={supervisorsQuery.isLoading}
                    isDisabled={userTabletSettingsQuery.isLoading}
                  />
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="defaultOperation"
              control={control}
              render={({ field }) => (
                <>
                  <label htmlFor="defaultOperation" className="label">
                    Operação
                  </label>
                  <Select
                    {...field}
                    id="defaultOperation"
                    placeholder="Selecione a operação padrão"
                    onChange={(value) => {
                      field.onChange(value);
                      changes.set();
                    }}
                    error={errors.defaultOperation?.message}
                    options={operationsQuery.data}
                    isLoading={operationsQuery.isLoading}
                    isDisabled={userTabletSettingsQuery.isLoading}
                  />
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="defaultEnterprise"
              control={control}
              rules={{ required: "Selecione uma empresa padrão" }}
              render={({ field }) => (
                <>
                  <label htmlFor="defaultEnterprise" className="label">
                    Empresa
                  </label>
                  <Select
                    {...field}
                    id="defaultEnterprise"
                    placeholder="Selecione a empresa padrão"
                    onChange={(value) => {
                      field.onChange(value);
                      changes.set();
                    }}
                    error={errors.defaultEnterprise?.message}
                    options={userTabletSettingsQuery.data?.configuracaoTabletEmpresa ?? []}
                    isLoading={userTabletSettingsQuery.isLoading}
                    isDisabled={userTabletSettingsQuery.isLoading}
                  />
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="defaultDeposit"
              control={control}
              rules={{ required: "Selecione um depósito padrão" }}
              render={({ field }) => (
                <>
                  <label htmlFor="defaultDeposit" className="label">
                    Depósito
                  </label>
                  <Select
                    {...field}
                    id="defaultDeposit"
                    placeholder="Selecione o depósito padrão"
                    onChange={(value) => {
                      field.onChange(value);
                      changes.set();
                    }}
                    error={errors.defaultDeposit?.message}
                    options={depositsQuery.data}
                    isLoading={depositsQuery.isLoading}
                    isDisabled={userTabletSettingsQuery.isLoading}
                  />
                </>
              )}
            />
          </div>
        </>
      ) : (
        <div className="loadingContainer" style={{ height: "500px", gridColumn: "1/-1", justifyContent: "center" }}>
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}
