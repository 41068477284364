import React from "react";
import { Route, Routes } from "react-router-dom";

import useValidityTableItems from "./hooks/useValidityTableItems";
import List from "./List/List";
import Micros from "./Micros/Micros";

export function ValidityTableItems({ selectedTable }) {
  const data = useValidityTableItems({ selectedTable });

  return (
    <Routes>
      <Route path="/" element={<List selectedTable={selectedTable} data={data} />} />
      <Route path="micros/*" element={<Micros selectedTable={selectedTable} />} />
    </Routes>
  );
}
