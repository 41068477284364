import React from "react";
import useMicros from "../hooks/useMicros";
import { Circle } from "../../../../../components/Loading";

import styles from "./Micros.module.css";
import { formatMoney } from "../../../../../helpers/format";
import { usePreviousRoute } from "../../../../../hooks/navigation";
import { Button, Input, LinkButton, Select } from "../../../../../components/Form";
import { Controller } from "react-hook-form";

interface Props {
  selectedTable: any;
}

export default function Micros({ selectedTable }: Props) {
  const data = useMicros({ selectedTable });

  const previousRoute = usePreviousRoute();

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = data.formEditMicro;

  const editedMicro = watch("microEditado");

  return (
    <>
      <div className="container">
        <nav className={styles.navContainer}>
          <LinkButton buttonStyle="backButton" to={previousRoute} />
          <h2>{selectedTable.value.tabela.nome}</h2>
          <Button className={styles.btnNewMicro} onClick={data.openModal}>
            Novo Micro
          </Button>
        </nav>
        <span className="separator" />
        {data.validityTableMicrosQuery.data.length > 0 && !data.validityTableMicrosQuery.isPending ? (
          <ul className={styles.microsContainer}>
            {data.validityTableMicrosQuery.data?.map((micro) => (
              <li className={styles.microItem} key={micro.micro.idComplementar}>
                <h5 className={styles.microItem__title}>
                  {micro.micro.idComplementar} | {micro.micro.descricaoComplementar}
                </h5>
                <ul className={styles.microItem__details}>
                  <li>
                    <span className={styles.microItem__detailInfo}>Símbolo:</span> {micro.micro.simbolo}
                  </li>
                  <li>
                    <span className={styles.microItem__detailInfo}>Fator de conversão:</span>{" "}
                    {micro.micro.fatorConversao}
                  </li>
                  <li>
                    <span className={styles.microItem__detailInfo}>Preço:</span> {formatMoney(micro.precoMicro)}
                  </li>
                  <li>
                    <span className={styles.microItem__detailInfo}>Preço Custo:</span>{" "}
                    {formatMoney(micro.precoCustoMicro)}
                  </li>
                </ul>
                <div className={styles.btnEditMicroContainer}>
                  <Button
                    className={styles.btnEditMicroContainer__btnEdit}
                    variant="neutral"
                    onClick={() => {
                      data.selectMicroToEdit(micro);
                    }}
                  >
                    Editar
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        ) : data.validityTableMicrosQuery.isPending ? (
          <div className="loadingContainer" style={{ height: "500px", justifyContent: "center" }}>
            <Circle size={100} />
          </div>
        ) : (
          <p className="lineCardMessage">Nenhum Micro Econtrado Para Esta Tabela</p>
        )}
      </div>
      <dialog
        className={`modal ${styles.modalUpdateCommissionPercent}`}
        ref={data.modalUpdateMicroRef}
        onClose={() => {
          reset();
        }}
      >
        <div className="modalContent">
          {!data.saveMicroQuery.isPending ? (
            <>
              <div className={styles.modalFormContainer__titleContainer}>
                <h3>
                  {editedMicro
                    ? `${editedMicro?.micro.idComplementar} | ${editedMicro?.micro.descricaoComplementar}`
                    : "Adicionar Micro"}
                </h3>
              </div>
              <div className={styles.modalFormContainer}>
                {!editedMicro && (
                  <div className={styles.modalFormContainer__newMicro}>
                    <Controller
                      name="novoMicro"
                      control={control}
                      rules={{
                        validate: (value) => {
                          return editedMicro && !value ? "Selecione um micro para adicionar" : undefined;
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <label htmlFor="novoMicro" className="label">
                            Novo Micro
                          </label>
                          <Select
                            {...field}
                            id="novoMicro"
                            options={data.microOptionsQuery.data}
                            placeholder="Selecione o micro que deseja adicionar"
                            error={errors.novoMicro?.message}
                            isLoading={data.microOptionsQuery.isPending}
                          />
                        </>
                      )}
                    />
                  </div>
                )}
                <div>
                  <Controller
                    name="precoMicro"
                    control={control}
                    rules={{
                      required: "Preecha o preço",
                    }}
                    render={({ field }) => (
                      <>
                        <label htmlFor="precoMicro" className="label">
                          Preço Micro
                        </label>
                        <Input
                          {...field}
                          id="precoMicro"
                          type="number"
                          className="cleanInputNumber"
                          autoComplete="off"
                          onWheel={(e) => {
                            e.currentTarget.blur();
                          }}
                          placeholder="Digite o preço do micro"
                          error={errors.precoMicro?.message}
                        />
                      </>
                    )}
                  />
                </div>
                <div className={styles.modalFormContainer__percent}>
                  <Controller
                    name="precoCustoMicro"
                    control={control}
                    rules={{
                      required: "Preecha o preço de custo",
                    }}
                    render={({ field }) => (
                      <>
                        <label htmlFor="precoCustoMicro" className="label">
                          Preço Custo Micro
                        </label>
                        <Input
                          {...field}
                          id="precoCustoMicro"
                          type="number"
                          className="cleanInputNumber"
                          autoComplete="off"
                          onWheel={(e) => {
                            e.currentTarget.blur();
                          }}
                          placeholder="Digite o preço de custo do micro"
                          error={errors.precoCustoMicro?.message}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <span className="separator" />
              <div className={styles.modalButtonsContainer}>
                <Button
                  onClick={() => {
                    data.modalUpdateMicroRef.current?.close();
                  }}
                  variant="danger"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    handleSubmit(data.saveMicroQuery.save, (e) => console.log(e))();
                  }}
                >
                  Concluir
                </Button>
              </div>
            </>
          ) : (
            <div className="loadingContainer">
              <Circle size={100} />
            </div>
          )}
        </div>
      </dialog>
    </>
  );
}
