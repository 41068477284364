import React, { useEffect } from "react";
import { Table } from "../../../../../components/Data/XTable";
import { UseSistemas } from "../hooks/useSistemas";
import { LinkButton } from "../../../../../components/Form";
import { Circle } from "../../../../../components/Loading";

import styles from "./Lista.module.css";

interface Props {
  data: UseSistemas;
}

export default function Lista({ data }: Props) {
  useEffect(() => {
    data.sistemaSelecionado.setValue(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <nav className={styles.navContainer}>
        <LinkButton to={"novo"}>Novo Sistema</LinkButton>
      </nav>
      <span className="separator" />
      <div>
        {data.tableSistemas.data.length > 0 && !data.buscarSistema.loading ? (
          <Table tableData={data.tableSistemas} />
        ) : data.buscarSistema.loading ? (
          <div
            className="loadingContainer"
            style={{ height: "500px", justifyContent: "center" }}
          >
            <Circle size={100} />
          </div>
        ) : (
          <p className="lineCardMessage">Nenhum Sistema Encontrado</p>
        )}
      </div>
    </div>
  );
}
