import React, { CSSProperties, ReactNode } from "react";
import { UseStepsController } from "../hooks/useStepsController";

import styles from "./StepsController.module.css";

interface Props {
  pages: Page[];
  useStepsController: UseStepsController;
  disabled?: boolean;
}

type Page = {
  name: string;
  content: ReactNode;
};

export default function StepsController({ pages, useStepsController, disabled }: Props) {
  return (
    <div>
      <div
        className={styles.stepsContainer}
        style={
          {
            "--currentStep": useStepsController.currentStep,
          } as CSSProperties
        }
      >
        {pages.map((page, index) => (
          <button
            className={styles.stepsContainer__step}
            key={page.name}
            type="button"
            onClick={() => useStepsController.changeStep(index)}
            data-is-current={`${useStepsController.currentStep === index}`}
            disabled={disabled}
          >
            <p className={styles.stepsContainer__step__name}>{page.name}</p>
          </button>
        ))}
      </div>

      <div className={styles.pagesContainer}>{pages[useStepsController.currentStep].content}</div>
    </div>
  );
}
