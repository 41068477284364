import { QueryClient, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxios from "../../../api/hooks/useAxiosInstance";

export type Micro = {
  descontoMaximo: number;
  descricaoComplementar: string;
  fatorConversao: number;
  idComplementar: number;
  simbolo: string;
  situacaoComplementar: "A" | "I";
  valor: number;
  idEmpresa: number;
  dataManutencao: string;
  ultimoCadastroComplementar: string;
};

type RequestBody = {
  pagina: number;
  tamanho: number;
  descricao: string;
  status: "A" | "I";
};

type FetchResponse = DefaultFetchResponse<Micro[]>;

export function useSearchMicrosPaginated(
  options?: UseMutationOptions<
    AxiosResponse<FetchResponse, any>,
    AxiosError<DefaultErrorResponse, any>,
    RequestBody,
    unknown
  >,
  queryClient?: QueryClient
) {
  const axios = useAxios();

  const fetchData = async (body: RequestBody): Promise<AxiosResponse<FetchResponse>> => {
    const response = await axios.post<FetchResponse>("/products/searchMicrosPaginated", body);
    return response;
  };

  const query = useMutation(
    {
      mutationFn: fetchData,
      networkMode: "always",
      ...options,
    },
    queryClient
  );

  return {
    ...query,
    data: query.data?.data.object ?? [],
  };
}
