import React from "react";
import { Route, Routes } from "react-router-dom";
import Lista from "./Lista/Lista";
import InserirAtualizar from "./InserirAtualizar/InserirAtualizar";
import useTipos from "./hooks/useTipos";

export default function Tipos() {
  const data = useTipos();

  return (
    <Routes>
      <Route path="/" element={<Lista data={data} />} />
      <Route path="novo" element={<InserirAtualizar data={data} />} />
      <Route
        path="atualizar"
        element={<InserirAtualizar data={data} telaAtualizar={true} />}
      />
    </Routes>
  );
}
