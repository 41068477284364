import React, { useEffect } from "react";
import { Button, Input, LinkButton } from "../../../../../components/Form";
import { usePreviousRoute } from "../../../../../hooks/navigation";
import { UseSistemas } from "../hooks/useSistemas";
import { Navigate } from "react-router-dom";
import { useForm } from "../../../../../hooks/form";
import { isValid } from "../../../../../helpers/validations";
import { Circle } from "../../../../../components/Loading";

import styles from "./InserirAtualizar.module.css";

interface Props {
  data: UseSistemas;
  telaAtualizar?: boolean;
}

export default function InserirAtualizar({ data, telaAtualizar }: Props) {
  const previousRoute = usePreviousRoute();

  const nomeSistema = useForm({ required: true });

  useEffect(() => {
    if (telaAtualizar && data.sistemaSelecionado.value != null)
      nomeSistema.setValue(data.sistemaSelecionado.value.nome);
  }, []); // eslint-disable-line

  if (telaAtualizar && data.sistemaSelecionado.value == null)
    return <Navigate to={previousRoute} />;

  return (
    <div className="container">
      {!data.salvarAlteracoesSistema.loading ? (
        <>
          <nav>
            <LinkButton buttonStyle="backButton" to={previousRoute} />
          </nav>
          <span className="separator" />
          <div>
            <div>
              <label htmlFor="nomeSistema" className="label">
                Nome Sistema
              </label>
              <Input
                id="nomeSistema"
                placeholder="Digite o nome do sistema"
                value={nomeSistema.value}
                onChange={nomeSistema.onChange}
                onBlur={nomeSistema.onBlur}
                error={nomeSistema.error}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (!isValid(nomeSistema)) return;

                if (telaAtualizar)
                  data.salvarAlteracoesSistema.salvar(
                    nomeSistema.value,
                    data.sistemaSelecionado.value?.id
                  );
                else data.salvarAlteracoesSistema.salvar(nomeSistema.value);
              }}
            >
              Salvar
            </Button>
          </div>
        </>
      ) : (
        <div
          className="loadingContainer"
          style={{ height: "500px", justifyContent: "center" }}
        >
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}
