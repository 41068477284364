import React, { useNavigate } from "react-router-dom";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { usePreviousRoute } from "../../../../../hooks/navigation";
import { useForm, useSelect } from "../../../../../hooks/form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Sistema } from "../../Sistemas/hooks/useSistemas";
import { ITableData } from "../../../../../components/Data/XTable";
import { Button } from "../../../../../components/Form";
import { Pencil } from "phosphor-react";
import { isValid } from "../../../../../helpers/validations";

export type Tipo = {
  id: number;
  nome: string;
  status: Status;
  sistemaId: number;
  sistemaNome: string;
};

type Status = "A" | "I";

export default function useTipos() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const previousRoute = usePreviousRoute();
  const navigate = useNavigate();

  const [tipoSelecionado, setTipoSelecionado] = useState<Tipo | null>(null);

  const [tipos, setTipos] = useState<Tipo[]>([]);
  const tableTipos = useMemo<ITableData>(() => {
    const data = tipos.map((tipo) => ({
      ...tipo,
      editar: (
        <>
          <Button
            type="button"
            variant="edit"
            onClick={() => {
              setTipoSelecionado(tipo);
              navigate("atualizar");
            }}
            data-option-button
            style={{
              margin: "auto",
              padding: "0.5rem 0.75rem",
              minWidth: "100%",
              width: "1.25rem",
            }}
          >
            <Pencil weight="fill" />
          </Button>
        </>
      ),
    }));

    return {
      columns: [
        {
          title: "ID",
          objectName: "id",
          isSorted: true,
          style: { width: "10px" },
        },
        {
          title: "Tipo",
          objectName: "nome",
          isSorted: true,
        },
        {
          title: "Sistema",
          objectName: "sistemaNome",
          isSorted: true,
        },
        {
          title: "Status",
          objectName: "status",
          formatting: (value) => {
            if (value === "A") return "Ativo";
            else return "Inativo";
          },
          style: { width: "10px" },
          isSorted: true,
        },
        {
          title: "",
          objectName: "editar",
          isSorted: false,
          style: { width: "80px" },
        },
      ],
      data: data,
    };
  }, [navigate, tipos]);

  const sistema = useSelect<Sistema>({ type: "single", required: true });
  const [sistemaOptions, setSistemaOptions] = useState<
    ISelectOption<Sistema>[]
  >([]);

  const nomeTipo = useForm({ required: true });

  const status = useSelect<Status>({
    required: true,
    type: "single",
    defaultValue: { label: "Ativo", value: "A" },
  });
  const statusOptions = useMemo<ISelectOption<Status>[]>(
    () => [
      {
        label: "Ativo",
        value: "A",
      },
      { label: "Inativo", value: "I" },
    ],
    []
  );

  const buscandoSistemas = useLoading();

  const buscarSistemas = useCallback(async () => {
    try {
      buscandoSistemas.setLoading(true);
      const json = (await customFetch(
        "/admin/buscarSistemas",
        {}
      )) as DefaultFetchResponse<Sistema[]>;
      if (json.status === 200) {
        const options = json.object.map((sistema) => ({
          value: sistema,
          label: sistema.nome,
        }));
        setSistemaOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setSistemaOptions([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      buscandoSistemas.setLoading(false);
    }
  }, [Modal, buscandoSistemas, customFetch]);

  const buscandoTipos = useLoading();

  const buscarTipos = useCallback(async () => {
    try {
      buscandoTipos.setLoading(true);
      const json = (await customFetch(
        "/admin/buscarTipos",
        {}
      )) as DefaultFetchResponse<Tipo[]>;
      if (json.status === 200) {
        setTipos(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setTipos([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      buscandoTipos.setLoading(false);
    }
  }, [Modal, buscandoTipos, customFetch]);

  const salvandoAlteracoesTipo = useLoading();

  const salvarAlteracoesTipo = useCallback(
    async (idTipo?: number) => {
      if (!isValid(nomeTipo, sistema)) return;
      try {
        salvandoAlteracoesTipo.setLoading(true);
        const json = (await customFetch("/admin/inserirAtualizarTipo", {
          body: {
            id: idTipo,
            nome: nomeTipo.value,
            sistemaId: sistema.value?.value.id,
            status: status.value?.value,
          },
        })) as DefaultFetchResponse<any>;
        if (json.status === 200) {
          buscarTipos();
          await Modal.success(json.message);
          navigate(previousRoute);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        salvandoAlteracoesTipo.setLoading(false);
      }
    },
    [
      Modal,
      buscarTipos,
      customFetch,
      navigate,
      nomeTipo,
      previousRoute,
      salvandoAlteracoesTipo,
      sistema,
      status,
    ]
  );

  const resetForm = useCallback(() => {
    nomeTipo.reset();
    sistema.reset();
    status.reset();
    setTipoSelecionado(null);
  }, [nomeTipo, sistema, status]);

  useEffect(() => {
    buscarSistemas();
    buscarTipos();
  }, []); // eslint-disable-line

  return {
    nomeTipo,
    sistema,
    sistemaOptions,
    status,
    statusOptions,
    tipoSelecionado: {
      value: tipoSelecionado,
      setValue: setTipoSelecionado,
    },
    tableTipos,
    buscarTipos: {
      buscar: buscarTipos,
      loading: buscandoTipos.isLoading,
    },
    buscarSistemas: {
      buscar: buscarSistemas,
      loading: buscandoSistemas.isLoading,
    },
    salvarAlteracoesTipo: {
      salvar: salvarAlteracoesTipo,
      loading: salvandoAlteracoesTipo.isLoading,
    },
    resetForm,
  };
}

export type UseTipos = ReturnType<typeof useTipos>;
