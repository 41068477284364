import axios, { AxiosError } from "axios";
import { useAuth } from "../../hooks/contexts";

export default function useAxiosInstance() {
  const axiosInstance = axios.create();

  const auth = useAuth();

  axiosInstance.interceptors.response.use(undefined, (error: AxiosError<DefaultErrorResponse, any>) => {
    switch (error.code) {
      case "ERR_NETWORK":
        error.response!.data = {
          message: "Sem conexão com a internet!",
          object: undefined,
          status: 500,
        };

        throw error;

      case "ERR_BAD_RESPONSE":
        error.response!.data = {
          message: error.response?.data.message || "Erro de comunicação com o servidor!",
          object: error.response?.data.object || undefined,
          status: error.response?.data.status || 500,
        };

        throw error;

      case "ERR_BAD_REQUEST":
        if (error.response?.data.status === 401 || error.response?.data.message === "Unauthorized") auth.signOut();
        else {
          error.response!.data = {
            message: error.response?.data.message || error.message,
            object: error.response?.data.object || undefined,
            status: error.response?.data.status || 500,
          };
        }

        throw error;

      default:
        throw error;
    }
  });

  return axiosInstance;
}
