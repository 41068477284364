import React from "react";
import StepsController from "./components/StepsController/StepsController";
import useStepsController from "./components/hooks/useStepsController";
import Geral from "./components/Geral/Geral";
import FatAdubo from "./components/FatAdubo/FatAdubo";
import FatSemDef from "./components/FatSemDef/FatSemDef";
import FatFoliar from "./components/FatFoliar/FatFoliar";
import { Button, Select } from "../../../components/Form";
import { Controller } from "react-hook-form";

import styles from "./TabletSettings.module.css";
import useTabletSettings from "./hooks/useTabletSettings";
import { useModal } from "../../../hooks/contexts";

export function SettingsTabletSettings() {
  const Modal = useModal();

  const tabletSettings = useTabletSettings();

  const {
    user,
    changes,
    forms: {
      userForm: {
        control,
        formState: { errors },
      },
    },
    queries: { searchUsers, userTabletSettingsQuery },
  } = tabletSettings;

  const stepsController = useStepsController({ tabletSettings });

  return (
    <div className={`container`}>
      <div>
        <Controller
          name="user"
          control={control}
          render={({ field }) => (
            <>
              <label htmlFor="user" className="label">
                Usuário
              </label>
              <Select
                {...field}
                id="user"
                placeholder="Selecione o usuário que deseja alterar"
                onChange={async (value) => {
                  if (changes.isChangesDetected) {
                    const confirm = await Modal.confirm(
                      "Você tem alterações que ainda não foram salvas.<br />Deseja continuar?"
                    );
                    if (confirm) {
                      changes.reset();
                      field.onChange(value);
                    }
                  } else {
                    field.onChange(value);
                  }
                }}
                onInputChange={(value) => {
                  searchUsers.fetchDebounced({ descricao: value, quantidade: 100 });
                }}
                error={errors.user?.message}
                options={searchUsers.data ?? []}
                isLoading={searchUsers.isFetching}
                isDisabled={userTabletSettingsQuery.isLoading || stepsController.isSavingConfigs}
              />
            </>
          )}
        />
      </div>
      <span className="separator" />
      {user ? (
        <div className={`${styles.configsContainer}`}>
          <StepsController
            pages={[
              {
                name: "GERAL",
                content: <Geral tabletSettings={tabletSettings} />,
              },
              {
                name: "FAT. ADUBO",
                content: <FatAdubo tabletSettings={tabletSettings} />,
              },
              {
                name: "FAT. SEM/DEF",
                content: <FatSemDef tabletSettings={tabletSettings} />,
              },
              {
                name: "FAT. FOLIAR",
                content: <FatFoliar tabletSettings={tabletSettings} />,
              },
            ]}
            useStepsController={stepsController}
            disabled={stepsController.isSavingConfigs}
          />

          <Button
            className={styles.buttonSaveData}
            onClick={() => {
              stepsController.saveSettingsByStep();
            }}
            disabled={stepsController.isSavingConfigs || tabletSettings.queries.userTabletSettingsQuery.isLoading}
          >
            Salvar Dados
          </Button>
        </div>
      ) : (
        <p className="lineCardMessage">Selecione um usuário para alterar as configurações</p>
      )}
    </div>
  );
}
