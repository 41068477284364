import React from "react";
import { Route, Routes } from "react-router-dom";
import Sistemas from "./Sistemas/Sistemas";
import Tipos from "./Tipos/Tipos";
import Permissoes from "./Permissoes/Permissoes";

export default function ConfiguracoesPermissao() {
  return (
    <Routes>
      <Route path="sistemas/*" element={<Sistemas />} />
      <Route path="tipos/*" element={<Tipos />} />
      <Route path="permissoes/*" element={<Permissoes />} />
    </Routes>
  );
}
