import React from "react";
import { Route, Routes } from "react-router-dom";
import Lista from "./Lista/Lista";
import useBaixaManual from "./hooks/useBaixaManual";
import RealizarBaixas from "./RealizarBaixas/RealizarBaixas";

export default function BaixaManual() {
  const data = useBaixaManual();

  return (
    <Routes>
      <Route path="/" element={<Lista data={data} />} />
      <Route path="realizar-baixas" element={<RealizarBaixas data={data} />} />
    </Routes>
  );
}
