import React from "react";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../hooks/async";
import { useAuth, useModal } from "../../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../../hooks/form";
import { isValid } from "../../../../../helpers/validations";

export default function useValidityTableItems({ selectedTable }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const Auth = useAuth();

  const isFoliarTable = React.useMemo(() => {
    return (
      selectedTable.value?.tabela?.cabecalhoTabela?.grupo &&
      selectedTable.value?.tabela?.cabecalhoTabela?.grupo?.descricaoGrupo === "FOLIAR"
    );
  }, [selectedTable]);

  const [hasMicroPermission, setHasMicroPermission] = React.useState(false);

  const [lastModifiedId, setLastModifiedId] = React.useState(null);

  const itemFilter = useForm({ required: false });

  const searchingItems = useLoading();
  const searchingItemOptions = useLoading();
  const searchingCategoryOptions = useLoading();
  const savingItems = useLoading();

  const modalEditPercentsRef = React.useRef();

  const [items, setItems] = React.useState([]);
  const [itemOptions, setItemOptions] = React.useState([]);

  const item = useSelect({ type: "single", required: true });
  const costPrice = useForm({ type: "number", required: true });
  const sellPrice = useForm({ type: "number", required: true });
  const category = useSelect({ type: "single", required: isFoliarTable });
  const ggf = useForm({ type: "number", required: isFoliarTable });
  const packaging = useForm({ type: "number", required: isFoliarTable });
  const profitMargin = useForm({ type: "number", required: isFoliarTable });
  const icms = useForm({ type: "number", required: isFoliarTable });
  const freight = useForm({ type: "number", required: false });
  const premiation = useForm({ type: "number", required: isFoliarTable });
  const grossMargin = useForm({ type: "number", required: isFoliarTable });

  const resetForm = React.useCallback(() => {
    item.reset();
    costPrice.reset();
    sellPrice.reset();
    category.reset();
    ggf.reset();
    packaging.reset();
    profitMargin.reset();
    icms.reset();
    freight.reset();
    premiation.reset();
    grossMargin.reset();
  }, [item, category, costPrice, ggf, grossMargin, icms, freight, packaging, premiation, profitMargin, sellPrice]);

  const [categoryOptions, setCategoryOptions] = React.useState([]);

  const isUpdatingItem = React.useCallback(() => {
    return items.some((i) => i.formulado.idFormulado === item.value?.value?.formulado.idFormulado);
  }, [item, items]);

  const verifyMicroPermission = React.useCallback(async () => {
    try {
      const permissions = await Auth.searchUserPermissions();
      if (Auth.havePermission(permissions, "ATUALIZAR_VIGENCIA_MICRO")) {
        setHasMicroPermission(true);
      } else {
        setHasMicroPermission(false);
      }
    } catch (error) {
      setHasMicroPermission(false);
    }
  }, [Auth]);

  const searchCategoryOptions = React.useCallback(async () => {
    try {
      searchingCategoryOptions.setLoading(true);
      const json = await customFetch("/products/searchCategories", {
        body: {
          id: 0,
          categoria: "",
          status: "A",
        },
      });
      if (json.status === 200) {
        const options = json.object.map((category) => ({
          label: category.categoria,
          value: category.categoria,
        }));
        setCategoryOptions(options);
      } else {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingCategoryOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingCategoryOptions]);

  const searchItemOptions = React.useCallback(
    async (description = "") => {
      try {
        searchingItemOptions.setLoading(true);
        const json = await customFetch("/products/searchItemsToAddInValidity", {
          body: {
            idTabelaVigencia: selectedTable.value.tabela.id,
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.formulado.idFormulado} | ${option.formulado.descricaoFormulado}`,
            };
          });
          setItemOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingItemOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingItemOptions, selectedTable]
  );

  const searchItemOptionsDebounced = useDebounce(searchItemOptions);

  const searchValidityTableItems = React.useCallback(async () => {
    try {
      searchingItems.setLoading(true);
      const json = await customFetch("/products/searchValidityItems", {
        body: {
          idTabelaVigencia: selectedTable.value.tabela.id,
          descricao: "",
        },
      });
      if (json.status === 200) {
        setItems(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingItems.setLoading(false);
    }
  }, [Modal, customFetch, searchingItems, selectedTable]);

  const filterItemOptions = React.useCallback(() => {
    return itemOptions.filter(
      (option) => !items.some((item) => item.formulado.idFormulado === option.value.formulado.idFormulado)
    );
  }, [itemOptions, items]);

  const handleClickEditItem = React.useCallback(
    (selectedItem) => {
      const selected = {
        value: selectedItem,
        label: `${selectedItem.formulado.idFormulado} | ${selectedItem.formulado.descricaoFormulado}`,
      };

      if (isFoliarTable) {
        item.setValue(selected);
        costPrice.setValue(selectedItem.itensTabela.precoCusto);
        sellPrice.setValue(selectedItem.itensTabela.precoOriginal);
        category.setValue({
          label: selectedItem.itensTabela.categoria,
          value: selectedItem.itensTabela.categoria,
        });
        ggf.setValue(selectedItem.itensTabela.ggf);
        packaging.setValue(selectedItem.itensTabela.embalagem);
        profitMargin.setValue(selectedItem.itensTabela.margemSugerida);
        icms.setValue(selectedItem.itensTabela.icms);
        freight.setValue(selectedItem.itensTabela.frete | 0);
        premiation.setValue(selectedItem.itensTabela.premiacao);
        grossMargin.setValue(selectedItem.itensTabela.margemBruta);
      } else {
        item.setValue(selected);
        costPrice.setValue(selectedItem.itensTabela.precoCusto);
        sellPrice.setValue(selectedItem.itensTabela.precoOriginal);
      }

      document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
    },
    [
      isFoliarTable,
      item,
      costPrice,
      sellPrice,
      category,
      ggf,
      packaging,
      profitMargin,
      icms,
      freight,
      premiation,
      grossMargin,
    ]
  );

  const handleSaveItem = React.useCallback(
    (item, costPrice, sellPrice, category, ggf, packaging, profitMargin, icms, freight, premiation, grossMargin) => {
      return new Promise(async (resolve, reject) => {
        let ok = false;
        try {
          savingItems.setLoading(true);
          const json = await customFetch("/products/registerAndUpdateValidityItems", {
            body: {
              idTabelaVigencia: selectedTable.value.tabela.id,
              idItemTabela: item.itemTabela?.idItemTabela || item.itensTabela?.idItemTabela,
              idProduto: item.formulado.idFormulado,
              precoCusto: costPrice,
              precoOriginal: sellPrice,
              categoria: category,
              ggf: ggf,
              frete: freight,
              embalagem: packaging,
              margemSugerida: profitMargin,
              icms: icms,
              premiacao: premiation,
              margemBruta: grossMargin,
            },
          });
          if (json.status === 200) {
            await Modal.success(json.message);
            ok = true;
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          savingItems.setLoading(false);
          resolve(ok);
        }
      });
    },
    [Modal, customFetch, savingItems, selectedTable]
  );

  const handleAddItem = React.useCallback(async () => {
    let params;
    if (isFoliarTable) {
      if (
        !isValid(
          item,
          costPrice,
          sellPrice,
          category,
          ggf,
          packaging,
          profitMargin,
          icms,
          freight,
          premiation,
          grossMargin
        )
      )
        return;
      params = [
        item.value.value,
        Number(costPrice.value),
        Number(sellPrice.value),
        category.value?.value,
        Number(ggf.value),
        Number(packaging.value),
        Number(profitMargin.value),
        Number(icms.value),
        Number(freight.value),
        Number(premiation.value),
        Number(grossMargin.value),
      ];
    } else {
      if (!isValid(item, costPrice, sellPrice)) return;
      params = [item.value.value, Number(costPrice.value), Number(sellPrice.value)];
    }

    const confirm = await Modal.confirm("Tem certeza que deseja adicionar este item à tabela?");
    if (confirm) {
      const success = await handleSaveItem(...params);

      if (success) {
        if (!isUpdatingItem()) {
          setLastModifiedId(null);
        }

        searchValidityTableItems();

        resetForm();
      }
    }
  }, [
    Modal,
    isFoliarTable,
    handleSaveItem,
    item,
    costPrice,
    sellPrice,
    category,
    ggf,
    packaging,
    profitMargin,
    icms,
    freight,
    premiation,
    searchValidityTableItems,
    resetForm,
    grossMargin,
    isUpdatingItem,
  ]);

  React.useEffect(() => {
    if (selectedTable.value) {
      searchValidityTableItems();
      searchCategoryOptions();
      searchItemOptions();
    }
    verifyMicroPermission();
  }, []); // eslint-disable-line

  return {
    filterItemOptions,
    resetForm,
    item,
    searchItemOptionsDebounced,
    searchingItemOptions,
    costPrice,
    sellPrice,
    isFoliarTable,
    categoryOptions,
    category,
    searchingCategoryOptions,
    ggf,
    packaging,
    profitMargin,
    freight,
    icms,
    premiation,
    grossMargin,
    handleAddItem,
    isUpdatingItem,
    items,
    searchingItems,
    savingItems,
    itemFilter,
    lastModifiedId,
    setLastModifiedId,
    handleClickEditItem,
    modalEditPercentsRef,
    hasMicroPermission,
  };
}
